<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-3"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Aseguradoras</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Busqueda"
              single-line
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                far fa-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="codigo"
              label="Código"
              outlined
              dense
              :rules="rules"
            ></v-text-field>
            <v-text-field
              v-model="descripcion"
              label="Descripción"
              outlined
              dense
              :rules="rules"
            ></v-text-field>

            <v-text-field
              v-model="nombre_carpeta"
              label="Nombre carpeta"
              outlined
              dense
              :rules="rules"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="bandera == 1" @click="crearAseguradora()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera == 2"
            @click="actualizarAseguradora()"
            >ACTUALIZAR</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <lottiefile :estado="overlay"/>
  </v-row>
</template>

<script>
import axios from "axios";
import lottiefile from "../../LottieFiles/Animaciones.vue"

export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Código", value: "codigo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Nombre carpeta", value: "nombre_carpeta" },
      { text: "Opciones", value: "actions", align: "center", sorteable: false },
    ],
    items: [],
    dialog: false,
    id_aseguradora: null,
    codigo: null,
    descripcion: null,
    nombre_carpeta: "",
    rules: [(v) => !!v || "El campo es obligatorio"],
    bandera: -1,
    //OVERLAY
    overlay: false
  }),
  created() {
    this.listar();
  },
  components: {
    lottiefile
  },
  methods: {
    listar() {
      this.overlay = true;
      axios
        .get("api/Aseguradoras/Listar")
        .then((response) => {
          this.items = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error")
        });
    },
    abrirDialog() {
      this.bandera = 1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    editarItem(item) {
      this.id_aseguradora = item.id_aseguradoras;
      this.codigo = item.codigo;
      this.descripcion = item.descripcion;
      this.nombre_carpeta = item.nombre_carpeta;

      this.bandera = 2;
      this.dialog = true;
    },
    limpiar() {
      this.id_aseguradora = null;
      this.codigo = null;
      this.descripcion = null;
      this.nombre_carpeta = "";

      this.$refs.form.resetValidation();
    },
    crearAseguradora() {
      this.overlay = true;
      axios
        .post("api/Aseguradoras/Crear", {
          codigo: this.codigo,
          descripcion: this.descripcion,
          nombre_carpeta: this.nombre_carpeta
        })
        .then(() => {
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro creado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarAseguradora() {
      this.overlay = true;
      axios
        .put("api/Aseguradoras/Actualizar", {
          id_aseguradoras: this.id_aseguradora,
          codigo: this.codigo,
          descripcion: this.descripcion,
          nombre_carpeta: this.nombre_carpeta
        })
        .then(() => {
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro actualizado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>