<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <v-data-table
        :search="search"
        :items="items"
        :headers="headers"
        class="elevation-3"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Municipios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Busqueda"
              single-line
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                far fa-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="pb-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="codigo"
                label="Código"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                label="Nombre"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="bandera == 1" @click="crearMunicipio()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera == 2"
            @click="actualizarMunicipio()"
            >ACTUALIZAR</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <lottie :estado="overlay" />
  </v-row>
</template>

<script>
import axios from "axios";
import lottie from "../../LottieFiles/Animaciones.vue";

export default {
  data: () => ({
    search: "",
    items: [],
    headers: [
      { text: "Código", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Opciones", value: "actions", align: "center", sorteable: false },
    ],
    id_municipio: "",
    codigo: "",
    nombre: "",
    dialog: false,
    bandera: -1,
    overlay: false,
  }),
  created() {
    this.listar();
  },
  components: {
    lottie,
  },
  methods: {
    listar() {
      this.overlay = true;
      axios
        .get("api/Municipios/Listar")
        .then((response) => {
          this.items = response.data;
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    abrirDialog() {
      this.bandera = 1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    editarItem(item) {
      this.id_municipio = item.id_municipios;
      this.codigo = item.codigo;
      this.nombre = item.nombre;

      this.bandera = 2;
      this.dialog = true;
    },
    limpiar() {
      this.id_municipio = "";
      this.codigo = "";
      this.nombre = "";
    },
    crearMunicipio() {
      this.overlay = true;
      axios
        .post("api/Municipios/Crear", {
          codigo: this.codigo,
          nombre: this.nombre,
        })
        .then(() => {
          this.cerrarDialog();
          this.listar();
          this.alerta("success", "Registro creado con exito.");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarMunicipio() {
      this.overlay = true;
      axios
        .put("api/Municipios/Actualizar", {
          id_municipios: this.id_municipio,
          codigo: this.codigo,
          nombre: this.nombre,
        })
        .then(() => {
          this.cerrarDialog();
          this.listar();
          this.alerta("success", "Registro creado con exito.");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>