<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers_beneficiarios"
        :items="items_beneficiarios"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Beneficiarios Cesión </v-toolbar-title>
            <v-divider vertical inset class="mx-4"></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="nuevoBeneficiario()">Nuevo</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.action_edit`]="{ item }">
          <v-btn icon @click="editarBeneficiarioCesion(item)">
            <v-icon> far fa-edit </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog_beneficiarios_cesion" width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-text-field
            v-model="nombre_beneficiario_cesion"
            label="Nombre"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="flag_nuevo_editar == 1"
            color="primary"
            @click="crearBeneficiarioBanco()"
            >Guardar</v-btn
          >
          <v-btn
            v-if="flag_nuevo_editar == 2"
            color="primary"
            @click="actualizarBeneficiarioBanco()"
          >
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    headers_beneficiarios: [
      { text: "Beneficiario", value: "nombre" },
      {
        text: "Editar",
        value: "action_edit",
        align: "center",
        sortable: false,
      },
    ],
    dialog_beneficiarios_cesion: false,
    items_beneficiarios: [],
    id_beneficiario_cesion: 0,
    nombre_beneficiario_cesion: "",
    flag_nuevo_editar: 1,
  }),
  created() {
    this.listar();
  },
  methods: {
    listar() {
      axios.get("api/BeneficiariosCesion/Listar").then((response) => {
        this.items_beneficiarios = response.data;
      });
    },
    nuevoBeneficiario() {
      this.flag_nuevo_editar = 1;

      this.dialog_beneficiarios_cesion = true;
    },
    crearBeneficiarioBanco() {
      axios
        .post("api/BeneficiariosCesion/Crear", {
          nombre: this.nombre_beneficiario_cesion,
        })
        .then(() => {
          this.listar();
          this.cerrarDialog();
        });
    },
    editarBeneficiarioCesion(item) {
      this.flag_nuevo_editar = 2;
      this.id_beneficiario_cesion = item.id_beneficiario_cesion;
      this.nombre_beneficiario_cesion = item.nombre;

      this.dialog_beneficiarios_cesion = true;
    },
    actualizarBeneficiarioBanco() {
      axios
        .put("api/BeneficiariosCesion/Actualizar", {
          id_beneficiario_cesion: this.id_beneficiario_cesion,
          nombre: this.nombre_beneficiario_cesion,
        })
        .then(() => {
          this.listar();
          this.cerrarDialog();
        });
    },
    cerrarDialog() {
      this.dialog_beneficiarios_cesion = false;

      this.flag_nuevo_editar = 1;
      this.id_beneficiario_cesion = 0;
      this.nombre_beneficiario_cesion = "";
    },
  },
};
</script>