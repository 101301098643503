<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title>La sesión esta por cerrarse</v-card-title>
        <v-card-text class="text-center">
          <p class="subtitle-1">
            De click en seguir navegando para mantener la sesion activa.
          </p>
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="contador"
            color="red"
          >
            {{ contador }}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: true,
    interval: {},
    contador: 100,
  }),
  mounted() {
    this.interval = setInterval(() => {
      if (this.contador == 0) {
        this.eliminarToken();
      }
      this.contador -= 10;
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    eliminarToken() {
      axios
        .delete("api/Seguridades/Eliminar/" + localStorage.getItem("sesion"))
        .then(() => {
          this.dialog = false;
          this.contador = 100;
          this.$store.dispatch("salir");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>