<template>
  <v-row no-gutters class="fill-height">
    <v-col cols="12">
      <v-card class="ma-3">
        <v-card-title>
          <v-row>
            <v-col cols="auto">FECHA:</v-col>
            <v-col cols="2">
              <v-text-field
                type="date"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn>Visualizar</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <div class="title font-weight-bold">Clientes</div>
              <v-data-table
                v-model="clientes"
                :headers="headers_clientes"
                :items="items_clientes"
                :single-select="false"
                item-key="codigo"
                show-select
                height="300"
                class="borde"
              >
              </v-data-table>
            </v-col>
            <v-col cols="6">
              <div class="title font-weight-bold">Aseguradoras</div>
              <v-data-table
                v-model="aseguradoras"
                :headers="headers_aseguradora"
                :items="items_aseguradoras"
                :single-select="false"
                item-key="codigo"
                show-select
                height="300"
                class="borde"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mx-3">
        <v-card-text>
          <v-data-table :headers="headers_resultado" :items="items_resultado">
            <template v-slot:top>
              <v-toolbar flat v-if="items_resultado.lenght > 0">
                <v-spacer></v-spacer>
                <v-btn> Imprimir </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <vue-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="F1"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="792px"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
      class="mx-auto"
    >
    <section slot="pdf-content"></section>
    </vue-html2pdf>
  </v-row>
</template>

<script>
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";

export default {
  data: () => ({
    clientes: [],
    aseguradoras: [],
    headers_clientes: [
      { text: "Codigo", value: "codigo" },
      { text: "Nombre", value: "nombre" },
    ],
    headers_aseguradora: [
      { text: "Codigo", value: "codigo" },
      { text: "Nombre", value: "descripcion" },
    ],
    items_clientes: [],
    items_aseguradoras: [],
    headers_resultado: [
      { text: "N° Póliza", value: "num_poliza" },
      { text: "Cliente", value: "cliente" },
      { text: "Teléfono", value: "telefono" },
      { text: "Celular", value: "celular" },
      { text: "Email", value: "correo" },
      { text: "Num Certificado", value: "num_certificado" },
      { text: "Aseguradora", value: "aseguradora" },
      { text: "Tipo seguro", value: "servicio" },
      { text: "Fecha", value: "fecha" },
      { text: "Monto", value: "monto" },
    ],
    items_resultado: [],
  }),
  components: {
    VueHtml2pdf,
  },
  created() {
    this.listarClientes();
    this.listarAseguradoras();
  },
  methods: {
    listarClientes() {
      axios
        .get("api/Clientes/Listar")
        .then((response) => {
          this.items_clientes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarAseguradoras() {
      axios
        .get("api/Aseguradoras/Listar")
        .then((response) => {
          this.items_aseguradoras = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.borde {
  border: 1px black solid;
}
</style>