<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-3"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Clientes</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-file-input
              label="Clientes"
              hide-details
              dense
              @change="cargarClientes"
              v-if="false"
              >Cargar clientes</v-file-input
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Busqueda"
              single-line
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                far fa-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actionsRL`]="{ item }">
          <v-icon
            v-if="item.tipo_persona === 'PERSONA JURIDICA'"
            @click="adicionarRepreLegal(item)"
          >
            fa-solid fa-user-tie
          </v-icon>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="800" persistent scrollable>
      <v-card>
        <v-card-text class="px-3 pt-3">
          <v-tabs v-model="tabs" background-color="primary" dark>
            <v-tab>Asegurado</v-tab>
            <v-tab v-if="asegurado_pagador">Pagador</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-form ref="form">
                <!-- PARTE 1 -->
                <v-row no-gutters class="mt-3 lineaMargen">
                  <v-col cols="12">
                    <v-checkbox
                      v-model="asegurado_pagador"
                      label="¿Asegurado diferente a pagador?"
                      hide-details
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3">
                    <v-autocomplete
                      v-model="tipo_persona"
                      label="Tipo persona"
                      :items="items_tipo_persona"
                      outlined
                      dense
                      hide-details
                      class="pr-3"
                      :rules="rules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3 pr-3">
                    <v-text-field
                      v-model="codigo"
                      label="Código"
                      outlined
                      dense
                      hide-details
                      :rules="rules"
                      >{{ tipoPersona }}</v-text-field
                    >
                  </v-col>
                  <v-col cols="12" class="pt-3" md="4">
                    <v-text-field
                      v-model="fecha_ingreso"
                      label="Fecha ingreso"
                      outlined
                      dense
                      type="date"
                      :rules="rules"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-3">
                    <v-text-field
                      v-model="nombre"
                      label="Nombre"
                      outlined
                      dense
                      :rules="rules"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pl-3 pt-3">
                    <v-text-field
                      v-model="nombre_carpeta"
                      label="Nombre según carpeta"
                      outlined
                      dense
                      :rules="rules"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-3">
                    <v-text-field
                      v-model="razon_social"
                      label="Razón social"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- PARTE 2 -->
                <v-row no-gutters class="mt-3 lineaMargen">
                  <v-col cols="12" md="6" class="pr-3 pt-3">
                    <v-autocomplete
                      v-model="departamento"
                      label="Departamento"
                      :items="items_departamento"
                      item-text="nombre"
                      outlined
                      dense
                      :rules="rules"
                      hide-details
                      return-object
                      @change="listarMunicipios()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-3">
                    <v-autocomplete
                      v-model="id_municipio"
                      label="Municipio"
                      :items="items_municipio"
                      item-text="nombre"
                      item-value="id_municipios"
                      outlined
                      dense
                      :rules="rules"
                      hide-details
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="pt-3">
                    <v-text-field
                      v-model="direccion"
                      label="Dirección"
                      outlined
                      dense
                      :rules="rules"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3 pr-3">
                    <v-text-field
                      v-model="fecha_nacimiento"
                      label="Fecha nacimiento"
                      outlined
                      dense
                      type="date"
                      :rules="rules"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3 pr-3">
                    <v-text-field
                      v-model="dui"
                      label="DUI"
                      outlined
                      dense
                      hide-details
                      maxlength="9"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3">
                    <v-text-field
                      v-model="nit"
                      label="NIT"
                      outlined
                      dense
                      hide-details
                      maxlength="14"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3 pr-3">
                    <v-text-field
                      v-model="celular"
                      label="Celular"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3 pr-3">
                    <v-text-field
                      v-model="telefono"
                      label="Teléfono"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pt-3">
                    <v-text-field
                      v-model="correo"
                      label="Correo"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- PARTE 3 -->
                <v-row no-gutters class="mt-3 lineaMargen">
                  <v-col cols="12" md="6" class="pt-3 pr-3">
                    <v-text-field
                      v-model="giro"
                      label="Giro"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pt-3">
                    <v-autocomplete
                      v-model="tamaño"
                      label="Tamaño"
                      :items="items_tamaño"
                      outlined
                      dense
                      hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" class="pt-3">
                    <v-text-field
                      v-model="registro"
                      label="Registro"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-3 pr-3">
                    <v-text-field
                      v-model="contacto_1"
                      label="Contacto 1"
                      outlined
                      dense
                      hide-details
                      :rules="rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-3">
                    <v-text-field
                      v-model="telefono_1"
                      label="Teléfono 1"
                      outlined
                      dense
                      hide-details
                      :rules="rules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-3 pr-3">
                    <v-text-field
                      v-model="contacto_2"
                      label="Contacto 2"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-3">
                    <v-text-field
                      v-model="telefono_2"
                      label="Teléfono 2"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-3 pr-3">
                    <v-text-field
                      v-model="contacto_3"
                      label="Contacto 3"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="pt-3">
                    <v-text-field
                      v-model="telefono_3"
                      label="Teléfono 3"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <!-- PARTE 1 -->
              <v-row no-gutters class="mt-3 lineaMargen">
                <v-col cols="12" md="4" class="pt-3">
                  <v-autocomplete
                    v-model="tipo_persona_p"
                    label="Tipo persona"
                    :items="items_tipo_persona"
                    outlined
                    dense
                    hide-details
                    class="pr-3"
                    :rules="rules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="pt-3 pr-3">
                  <v-text-field
                    v-model="codigo_p"
                    label="Código"
                    outlined
                    dense
                    hide-details
                    :rules="rules"
                    >{{ tipoPersonaP }}</v-text-field
                  >
                </v-col>
                <v-col cols="12" class="pt-3" md="4">
                  <v-text-field
                    v-model="fecha_ingreso_p"
                    label="Fecha ingreso"
                    outlined
                    dense
                    type="date"
                    :rules="rules"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-3">
                  <v-text-field
                    v-model="nombre_p"
                    label="Nombre"
                    outlined
                    dense
                    :rules="rules"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pl-3 pt-3">
                  <v-text-field
                    v-model="nombre_carpeta_p"
                    label="Nombre según carpeta"
                    outlined
                    dense
                    :rules="rules"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-3">
                  <v-text-field
                    v-model="razon_social_p"
                    label="Razón social"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- PARTE 2 -->
              <v-row no-gutters class="mt-3 lineaMargen">
                <v-col cols="12" md="6" class="pr-3 pt-3">
                  <v-autocomplete
                    v-model="departamento_p"
                    label="Departamento"
                    :items="items_departamento"
                    item-text="nombre"
                    outlined
                    dense
                    :rules="rules"
                    hide-details
                    return-object
                    @change="listarMunicipiosP()"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" class="pt-3">
                  <v-autocomplete
                    v-model="id_municipio_p"
                    label="Municipio"
                    :items="items_municipio_p"
                    item-text="nombre"
                    item-value="id_municipios"
                    outlined
                    dense
                    :rules="rules"
                    hide-details
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-3">
                  <v-text-field
                    v-model="direccion_p"
                    label="Dirección"
                    outlined
                    dense
                    :rules="rules"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-3 pr-3">
                  <v-text-field
                    v-model="fecha_nacimiento_p"
                    label="Fecha nacimiento"
                    outlined
                    dense
                    type="date"
                    :rules="rules"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-3 pr-3">
                  <v-text-field
                    v-model="dui_p"
                    label="DUI"
                    outlined
                    dense
                    hide-details
                    maxlength="9"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-3">
                  <v-text-field
                    v-model="nit_p"
                    label="NIT"
                    outlined
                    dense
                    hide-details
                    maxlength="14"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-3 pr-3">
                  <v-text-field
                    v-model="celular_p"
                    label="Celular"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-3 pr-3">
                  <v-text-field
                    v-model="telefono_p"
                    label="Teléfono"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="pt-3">
                  <v-text-field
                    v-model="correo_p"
                    label="Correo"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- PARTE 3 -->
              <v-row no-gutters class="mt-3 lineaMargen">
                <v-col cols="12" md="6" class="pt-3 pr-3">
                  <v-text-field
                    v-model="giro_p"
                    label="Giro"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pt-3">
                  <v-autocomplete
                    v-model="tamaño_p"
                    label="Tamaño"
                    :items="items_tamaño"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-3">
                  <v-text-field
                    v-model="registro_p"
                    label="Registro"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-3 pr-3">
                  <v-text-field
                    v-model="contacto_1_p"
                    label="Contacto 1"
                    outlined
                    dense
                    hide-details
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-3">
                  <v-text-field
                    v-model="telefono_1_p"
                    label="Teléfono 1"
                    outlined
                    dense
                    hide-details
                    :rules="rules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-3 pr-3">
                  <v-text-field
                    v-model="contacto_2_p"
                    label="Contacto 2"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-3">
                  <v-text-field
                    v-model="telefono_2_p"
                    label="Teléfono 2"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-3 pr-3">
                  <v-text-field
                    v-model="contacto_3_p"
                    label="Contacto 3"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-3">
                  <v-text-field
                    v-model="telefono_3_p"
                    label="Teléfono 3"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="bandera == 1" @click="crearCliente()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera == 2"
            @click="actualizarCliente()"
            >ACTUALIZAR</v-btn
          >
          <v-btn color="primary" @click="abrirDialogArchivo()">Archivos</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_archivos" width="800" persistent>
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text class="px-3">
          <!-- SECCION 1 -->
          <v-row class="mb-1 lineaMargen">
            <v-col cols="12">
              <v-file-input
                v-model="file_input"
                label="Seleccione los archivos"
                counter
                outlined
                dense
                multiple
                :show-size="1000"
              >
              </v-file-input>
            </v-col>
          </v-row>
          <!-- SECCION 2 -->
          <v-col cols="12" class="mt-3 lineaMargen">
            <v-data-table
              v-model="archivos_seleccionados"
              :headers="headers_archivos"
              :items="items_archivos"
              item-key="id"
              show-select
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green"
                    dark
                    v-if="
                      bandera_archivos == 2 && archivos_seleccionados.length > 0
                    "
                    @click="descargarArchivo()"
                    >Descargar archivos</v-btn
                  >
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="vistaPreviaArchivo(item)">
                  far fa-folder-open
                </v-icon>
              </template>
              <template v-slot:[`item.action_delete`]="{ item }">
                <v-icon @click="confirmarEliminarArchivo(item)">
                  far fa-trash-alt
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearArchivoCliente()"
            v-if="bandera_archivos == 2"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera_archivos == 1 && this.file_input.length > 0"
            @click="dialog_archivos = false"
            >Continuar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogArchivos()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="representante_legal" width="500" persistent>
      <v-card>
        <v-card-title>Representante Legal</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="nombre_RL"
                label="Nombre"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="dui_RL"
                label="Dui"
                outlined
              ></v-text-field>
              <v-checkbox
                v-model="ppe_RL"
                label="Persona politica expuesta"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="agregarRL()">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="representante_legal = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmacion_eliminar_archivo" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar el archivo?</v-card-title>
        <v-card-text>
          <span style="font-weight: bold">Nombre:</span> {{ nombre_archivo }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarArchivo()">Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmacion_eliminar_archivo = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <lottie :estado="overlay" />
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import apiOneDrive from "../../ApiOneDrive/API";
import apiLocal from "../../AccesosToken/Api";
import lottie from "../../LottieFiles/Animaciones.vue";
import * as XLSX from "xlsx";

export default {
  data: () => ({
    tabs: null,
    asegurado_pagador: false,
    search: "",
    headers: [
      { text: "Código", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Dirección", value: "direccion" },
      { text: "Teléfono", value: "telefono" },
      { text: "Registro", value: "registro" },
      { text: "Dui", value: "dui" },
      { text: "Nit", value: "nit" },
      { text: "Giro", value: "giro" },
      { text: "Opciones", value: "actions", align: "center", sorteable: false },
      {
        text: "Repre. Legal",
        value: "actionsRL",
        align: "center",
        sorteable: false,
      },
    ],
    items: [],
    headers_archivos: [
      { text: "Nombre", value: "name" },
      { text: "Ver", value: "actions", align: "center", sorteable: false },
      {
        text: "Eliminar",
        value: "action_delete",
        align: "center",
        sorteable: false,
      },
    ],
    items_archivos: [],
    items_departamento: [],
    items_municipio: [],
    items_tamaño: ["PEQUEÑA", "MEDIANA", "GRANDE", "OTROS"],
    rules: [(v) => !!v || "El campo es obligatorio"],
    items_tipo_persona: ["PERSONA NATURAL", "PERSONA JURIDICA"],
    dialog: false,
    bandera: -1,
    dialog_archivos: false,
    id_cliente: null,
    tipo_persona: null,
    codigo: null,
    fecha_ingreso: null,
    nombre: null,
    nombre_carpeta: null,
    razon_social: null,
    id_departamento: null,
    departamento: null,
    id_municipio: null,
    direccion: null,
    fecha_nacimiento: null,
    dui: null,
    nit: null,
    celular: null,
    telefono: null,
    correo: null,
    giro: null,
    tamaño: null,
    registro: null,
    contacto_1: null,
    contacto_2: null,
    contacto_3: null,
    telefono_1: null,
    telefono_2: null,
    telefono_3: null,
    confirmacion_eliminar_archivo: false,
    //ASEGURADO PAGADOR
    id_cliente_p: null,
    tipo_persona_p: null,
    codigo_p: null,
    fecha_ingreso_p: null,
    nombre_p: null,
    nombre_carpeta_p: null,
    razon_social_p: null,
    id_departamento_p: null,
    departamento_p: null,
    id_municipio_p: null,
    items_municipio_p: [],
    direccion_p: null,
    fecha_nacimiento_p: null,
    dui_p: null,
    nit_p: null,
    celular_p: null,
    telefono_p: null,
    correo_p: null,
    giro_p: null,
    tamaño_p: null,
    registro_p: null,
    contacto_1_p: null,
    contacto_2_p: null,
    contacto_3_p: null,
    telefono_1_p: null,
    telefono_2_p: null,
    telefono_3_p: null,
    //ARCHIVOS
    file_input: [],
    archivos_seleccionados: [],
    singleSelect: false,
    id_archivos_clientes: null,
    descripcion_archivo: null,
    bandera_archivos: -1,
    //overlay
    overlay: false,
    //REPRESENTANTE LEGAL
    representante_legal: false,
    nombre_RL: null,
    dui_RL: null,
    ppe_RL: false,
    //OTROS
    id_archivo: 0,
    nombre_archivo: "",
  }),
  created() {
    this.listar();
    this.listarDepartamentos();
  },
  components: {
    lottie,
  },
  computed: {
    tipoPersona() {
      if (this.tipo_persona == "PERSONA NATURAL") {
        this.nit = null;
        this.dui = this.codigo;
      } else {
        this.dui = null;
        this.nit = this.codigo;
      }
    },
    tipoPersonaP() {
      if (this.tipo_persona_p == "PERSONA NATURAL") {
        this.nit_p = null;
        this.dui_p = this.codigo_p;
      } else {
        this.dui_p = null;
        this.nit_p = this.codigo_p;
      }
    },
  },
  methods: {
    listar() {
      this.overlay = true;
      axios
        .get("api/Clientes/Listar")
        .then((response) => {
          this.items = response.data;
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    listarDepartamentos() {
      axios
        .get("api/Departamentos/Listar")
        .then((response) => {
          this.items_departamento = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarMunicipios() {
      this.id_departamento = this.departamento.id_departamentos;
      axios
        .get("api/Municipios/Listar/" + this.departamento.codigo)
        .then((response) => {
          this.items_municipio = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarMunicipiosP() {
      this.id_departamento_p = this.departamento_p.id_departamentos;
      axios
        .get("api/Municipios/Listar/" + this.departamento_p.codigo)
        .then((response) => {
          this.items_municipio_p = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirDialog() {
      this.bandera = 1;
      this.bandera_archivos = 1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
      this.limpiarP();
    },
    abrirDialogArchivo() {
      if (this.bandera == 2) {
        this.listarArchivos();
      }
      this.dialog_archivos = true;
    },
    cerrarDialogArchivos() {
      this.dialog_archivos = false;
      this.limpiarArchivos();
    },
    limpiarArchivos() {
      this.file_input = [];
      this.archivos_seleccionados = [];
      this.items_archivos = [];
      this.id_archivos_clientes = null;
      this.descripcion_archivo = null;
    },
    editarItem(item) {
      //console.log(item);
      this.id_cliente = item.id_cliente;
      this.id_municipio = item.id_municipios;
      this.tipo_persona = item.tipo_persona;
      this.codigo = item.codigo;
      this.fecha_ingreso = this.formatoFecha(item.fecha_ingreso);
      this.nombre = item.nombre;
      this.nombre_carpeta = item.nombre_carpeta.trim();
      this.razon_social = item.razon_social;
      this.direccion = item.direccion;
      this.fecha_nacimiento = this.formatoFecha(item.fecha_nacimiento);
      this.dui = item.dui;
      this.nit = item.nit;
      this.celular = item.celular;
      this.telefono = item.telefono;
      this.correo = item.correo;
      this.giro = item.giro;
      this.tamaño = item.tamaño;
      this.registro = item.registro;
      this.contacto_1 = item.contacto1;
      this.contacto_2 = item.contacto2;
      this.contacto_3 = item.contacto2;
      this.telefono_1 = item.telefono1;
      this.telefono_2 = item.telefono2;
      this.telefono_3 = item.telefono3;

      this.departamento = {
        id_departamentos: item.id_departamentos,
        codigo: item.codigo_departamento,
        nombre: item.nombre_departamento,
      };
      this.listarMunicipios();
      this.listarAseguradoPagador(item.id_cliente);

      this.bandera = 2;
      this.bandera_archivos = 2;
      this.dialog = true;
    },
    listarAseguradoPagador(id_cliente) {
      axios.get("api/Clientes/ListarP/" + id_cliente).then((response) => {
        //console.log(response.data);
        if (response.data.length == 1) {
          this.asegurado_pagador = true;
          response.data.forEach((element) => {
            this.id_cliente_p = element.id_cliente;
            this.id_municipio_p = element.id_municipios;
            this.tipo_persona_p = element.tipo_persona;
            this.codigo_p = element.codigo;
            this.fecha_ingreso_p = this.formatoFecha(element.fecha_ingreso);
            this.nombre_p = element.nombre;
            this.nombre_carpeta_p = element.nombre_carpeta.trim();
            this.razon_social_p = element.razon_social;
            this.direccion_p = element.direccion;
            this.fecha_nacimiento_p = this.formatoFecha(
              element.fecha_nacimiento
            );
            this.dui_p = element.dui;
            this.nit_p = element.nit;
            this.celular_p = element.celular;
            this.telefono_p = element.telefono;
            this.correo_p = element.correo;
            this.giro_p = element.giro;
            this.tamaño_p = element.tamaño;
            this.registro_p = element.registro;
            this.contacto_1_p = element.contacto1;
            this.contacto_2_p = element.contacto2;
            this.contacto_3_p = element.contacto2;
            this.telefono_1_p = element.telefono1;
            this.telefono_2_p = element.telefono2;
            this.telefono_3_p = element.telefono3;

            this.departamento_p = {
              id_departamentos: element.id_departamentos,
              codigo: element.codigo_departamento,
              nombre: element.nombre_departamento,
            };
            this.listarMunicipiosP();
          });
        }
      });
    },
    limpiar() {
      this.tipo_persona = null;
      this.codigo = null;
      this.fecha_ingreso = null;
      this.nombre = null;
      this.nombre_carpeta = null;
      this.razon_social = null;
      this.id_departamento = null;
      this.departamento = null;
      this.id_municipio = null;
      this.direccion = null;
      this.fecha_nacimiento = null;
      this.dui = null;
      this.nit = null;
      this.celular = null;
      this.telefono = null;
      this.correo = null;
      this.giro = null;
      this.tamaño = null;
      this.registro = null;
      this.contacto_1 = null;
      this.contacto_2 = null;
      this.contacto_3 = null;
      this.telefono_1 = null;
      this.telefono_2 = null;
      this.telefono_3 = null;
      this.nombre_RL = null;
      this.dui_RL = null;
      this.ppe_RL = false;
      this.items_municipio = [];
      this.asegurado_pagador = false;

      this.bandera = -1;
      this.bandera_archivos = -1;

      this.$refs.form.resetValidation();
    },
    limpiarP() {
      this.tipo_persona_p = null;
      this.codigo_p = null;
      this.fecha_ingreso_p = null;
      this.nombre_p = null;
      this.nombre_carpeta_p = null;
      this.razon_social_p = null;
      this.id_departamento_p = null;
      this.departamento_p = null;
      this.id_municipio_p = null;
      this.direccion_p = null;
      this.fecha_nacimiento_p = null;
      this.dui_p = null;
      this.nit_p = null;
      this.celular_p = null;
      this.telefono_p = null;
      this.correo_p = null;
      this.giro_p = null;
      this.tamaño_p = null;
      this.registro_p = null;
      this.contacto_1_p = null;
      this.contacto_2_p = null;
      this.contacto_3_p = null;
      this.telefono_1_p = null;
      this.telefono_2_p = null;
      this.telefono_3_p = null;
      this.nombre_RL = null;
      this.dui_RL = null;
      this.ppe_RL = false;
      this.items_municipio_p = [];
    },
    crearCliente() {
      if (
        this.tipo_persona == null ||
        this.codigo == null ||
        this.fecha_ingreso == null ||
        this.id_departamento == null ||
        this.id_municipio == null ||
        this.direccion == null ||
        this.fecha_nacimiento == null ||
        this.contacto_1 == null ||
        this.telefono_1 == null
      ) {
        this.alerta("info", "Complete los campos obligatorios");
        this.$refs.form.validate();
        return;
      }
      this.overlay = true;
      axios
        .post("api/Clientes/Crear", {
          id_departamentos: this.id_departamento,
          id_municipios: this.id_municipio,
          tipo_persona: this.tipo_persona,
          codigo: this.codigo,
          fecha_ingreso: this.fecha_ingreso,
          nombre: this.nombre,
          nombre_carpeta: this.nombre_carpeta.trim(),
          razon_social: this.razon_social,
          direccion: this.direccion,
          fecha_nacimiento: this.fecha_nacimiento,
          dui: this.dui,
          nit: this.nit,
          celular: this.celular,
          telefono: this.telefono,
          correo: this.correo,
          giro: this.giro,
          tamaño: this.tamaño,
          registro: this.registro,
          contacto1: this.contacto_1,
          contacto2: this.contacto_2,
          contacto3: this.contacto_3,
          telefono1: this.telefono_1,
          telefono2: this.telefono_2,
          telefono3: this.telefono_3,
          nombre_RL: this.nombre_RL,
          dui_Rl: this.dui_RL,
          ppe_RL: this.ppe_RL,
          id_cliente_p: 0,
        })
        .then((response) => {
          this.id_cliente = response.data;

          if (this.asegurado_pagador) {
            this.crearClienteP(response.data);
          }

          if (this.file_input.length > 0) {
            this.crearArchivoCliente();
            this.overlay = false;
            return;
          }

          this.cerrarDialog();
          this.listar();
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    crearClienteP(id_cliente) {
      if (this.asegurado_pagador) {
        this.overlay = true;
        axios
          .post("api/Clientes/Crear", {
            id_departamentos: this.id_departamento_p,
            id_municipios: this.id_municipio_p,
            tipo_persona: this.tipo_persona_p,
            codigo: this.codigo_p,
            fecha_ingreso: this.fecha_ingreso_p,
            nombre: this.nombre_p,
            nombre_carpeta: this.nombre_carpeta_p.trim(),
            razon_social: this.razon_social_p,
            direccion: this.direccion_p,
            fecha_nacimiento: this.fecha_nacimiento_p,
            dui: this.dui_p,
            nit: this.nit_p,
            celular: this.celular_p,
            telefono: this.telefono_p,
            correo: this.correo_p,
            giro: this.giro_p,
            tamaño: this.tamaño_p,
            registro: this.registro_p,
            contacto1: this.contacto_1_p,
            contacto2: this.contacto_2_p,
            contacto3: this.contacto_3_p,
            telefono1: this.telefono_1_p,
            telefono2: this.telefono_2_p,
            telefono3: this.telefono_3_p,
            nombre_RL: null,
            dui_Rl: null,
            ppe_RL: false,
            id_cliente_p: id_cliente,
          })
          .then((response) => {
            this.alerta("success", "Registro de pagador creado con exito");
            this.overlay = false;

            this.cerrarDialog();
            this.listar();
          })
          .catch(() => {
            this.overlay = false;
            this.alerta("error", "Sucedio algún error");
          });
      }
    },
    actualizarCliente() {
      if (
        this.tipo_persona == null ||
        this.codigo == null ||
        this.fecha_ingreso == null ||
        this.id_departamento == null ||
        this.id_municipio == null ||
        this.direccion == null ||
        this.fecha_nacimiento == null ||
        this.contacto_1 == null ||
        this.telefono_1 == null
      ) {
        this.alerta("info", "Complete los campos obligatorios");
        this.$refs.form.validate();
        return;
      }
      this.overlay = true;
      axios
        .put("api/Clientes/Actualizar", {
          id_cliente: this.id_cliente,
          id_departamentos: this.id_departamento,
          id_municipios: this.id_municipio,
          tipo_persona: this.tipo_persona,
          codigo: this.codigo,
          fecha_ingreso: this.fecha_ingreso,
          nombre: this.nombre,
          nombre_carpeta: this.nombre_carpeta.trim(),
          razon_social: this.razon_social,
          direccion: this.direccion,
          fecha_nacimiento: this.fecha_nacimiento,
          dui: this.dui,
          nit: this.nit,
          celular: this.celular,
          telefono: this.telefono,
          correo: this.correo,
          giro: this.giro,
          tamaño: this.tamaño,
          registro: this.registro,
          contacto1: this.contacto_1,
          contacto2: this.contacto_2,
          contacto3: this.contacto_3,
          telefono1: this.telefono_1,
          telefono2: this.telefono_2,
          telefono3: this.telefono_3,
          nombre_RL: this.nombre_RL,
          dui_Rl: this.dui_RL,
          ppe_RL: this.ppe_RL,
        })
        .then(() => {
          if (this.id_cliente_p == 0 || this.id_cliente_p == null) {
            this.crearClienteP(this.id_cliente);
            return;
          }
          if (this.id_cliente_p > 0) {
            this.actualizarClienteP();
            return;
          }
          this.cerrarDialog();
          this.listar();
          this.alerta("success", "Registro creado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarClienteP() {
      this.overlay = true;
      axios
        .put("api/Clientes/Actualizar", {
          id_cliente: this.id_cliente_p,
          id_departamentos: this.id_departamento_p,
          id_municipios: this.id_municipio_p,
          tipo_persona: this.tipo_persona_p,
          codigo: this.codigo_p,
          fecha_ingreso: this.fecha_ingreso_p,
          nombre: this.nombre_p,
          nombre_carpeta: this.nombre_carpeta_p.trim(),
          razon_social: this.razon_social_p,
          direccion: this.direccion_p,
          fecha_nacimiento: this.fecha_nacimiento_p,
          dui: this.dui_p,
          nit: this.nit_p,
          celular: this.celular_p,
          telefono: this.telefono_1_p,
          correo: this.correo_p,
          giro: this.giro_p,
          tamaño: this.tamaño_p,
          registro: this.registro_p,
          contacto1: this.contacto_1_p,
          contacto2: this.contacto_2_p,
          contacto3: this.contacto_3_p,
          telefono1: this.telefono_1_p,
          telefono2: this.telefono_2_p,
          telefono3: this.telefono_3_p,
          nombre_RL: null,
          dui_Rl: null,
          ppe_RL: false,
          id_cliente_p: this.id_cliente,
        })
        .then(() => {
          this.cerrarDialog();
          this.listar();
          this.alerta("success", "Registro actualizado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
    alertaLista(icon, mensaje, lista = []) {
      const list = document.createElement("ul");

      lista.forEach((element) => {
        const listItem = document.createElement("li");
        listItem.innerHTML =
          "<b>" + element.estatus + "</b>" + ": " + element.nombre;
        list.appendChild(listItem);
      });

      var Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        width: 600,
        showConfirmButton: false,
        showCloseButton: true,
        closeOnConfirm: false,
        allowOutsideClick: false,
        html: list,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-DD");
    },
    formatoBooleano(item) {
      if (item) {
        return "SI";
      } else {
        return "NO";
      }
    },
    //API DE ONE DRIVE///////////////////////////////////////////////////////
    async listarArchivos() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }

      var ruta =
        process.env.VUE_APP_RAIZ_CARPETA +
        "/1-Asegurados/" +
        this.nombre_carpeta.trim() +
        "/Documentos personales";

      const result = await apiOneDrive.metodos.verListaArchivos(
        this.$store.state.usuario.id_usuario,
        ruta
      );

      if (result == "Not Found") {
        return this.alerta("info", "No se encontro archivo");
      }
      this.items_archivos = result;
    },
    async crearArchivoCliente() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );
      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      }
      var listaCreados = [];

      //ITERAMOS LA CANTIDAD DE ARCHIVOS A GUARDAR EN ONE DRIVE
      this.file_input.forEach(async (element) => {
        const result = await apiOneDrive.metodos.CrearArchivosCliente(
          this.$store.state.usuario.id_usuario,
          element,
          this.nombre_carpeta.trim()
        );
        if (result.status == 201) {
          listaCreados.push({
            estatus: "Archivo creado",
            nombre: result.data.name,
          });
          this.alertaLista("info", "archivo(s) creado(s)", listaCreados);
        } else {
          listaCreados.push({
            estatus: "Archivo no creado",
            nombre: result.data.name,
          });
          this.alertaLista("info", "archivo(s) creado(s)", listaCreados);
        }
      });

      if (this.bandera_archivos == 2) {
        this.cerrarDialogArchivos();
      } else {
        this.listar();
        this.cerrarDialog();
        this.limpiar();
      }
    },
    async descargarArchivo() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );
      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        //ACTUALIZAR TOKEN
        await this.obtenerNuevoToken();
      } else {
        apiOneDrive.metodos.DescargarItems(
          this.$store.state.usuario.id_usuario,
          this.archivos_seleccionados
        );
      }
    },
    async vistaPreviaArchivo(item) {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const expires_in = await apiLocal.metodos.getItemObject(
        "expires_in",
        credenciales
      );

      if (expires_in == undefined) {
        this.alerta("info", "No ha iniciado sesion en One Drive");
        return;
      }

      if (Date.now() >= expires_in.valor) {
        await this.obtenerNuevoToken();
      } else {
        apiOneDrive.metodos.VerArchivo(
          this.$store.state.usuario.id_usuario,
          item.id
        );
      }
    },
    confirmarEliminarArchivo(item) {
      this.confirmacion_eliminar_archivo = true;
      this.id_archivo = item.id;
      this.nombre_archivo = item.name;
    },
    async eliminarArchivo() {
      if (this.id_archivo) {
        const result = await apiOneDrive.metodos.EliminarArchivo(
          this.$store.state.usuario.id_usuario,
          this.id_archivo
        );
        if (result == 204) {
          this.alerta("success", "Archivo eliminado");
          this.listarArchivos();
          this.confirmacion_eliminar_archivo = false;
        } else {
          this.alerta("error", "No se pudo eliminar el archivo");
          this.confirmacion_eliminar_archivo = false;
        }
      }
    },
    async obtenerNuevoToken() {
      const credenciales = await apiLocal.metodos.listarClavesOneDrive(
        this.$store.state.usuario.id_usuario
      );
      const refresh_token = await apiLocal.metodos.getItemObject(
        "refresh_token",
        credenciales
      ).valor;

      await apiLocal.metodos
        .getTokenRefresh(refresh_token)
        .then(async (response) => {
          await apiLocal.metodos.actualizarCredenciales(
            response.data,
            credenciales
          );
        });
    },
    //CARGA DE EARCHIVO EXCEL CSV
    cargarClientes(e) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(e);

      fileReader.onload = (e) => {
        this.agregarClienteCSV(this.processExcel(e.target.result));
      };
    },
    processExcel(data) {
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      var firstSheet = workbook.SheetNames[0];

      var data = this.to_json(workbook);
      return data;
    },
    to_json(workbook) {
      var result = {};
      workbook.SheetNames.forEach(function (sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });
        if (roa.length) result[sheetName] = roa;
      });
      return result.Sheet1;
    },
    async agregarClienteCSV(objectClientes = []) {
      this.overlay = true;
      let cont = 0;
      for (var i = 1; i < objectClientes.length; i++) {
        let cliente = {
          nombre: objectClientes[i][0],
          nombre_carpeta: objectClientes[i][1],
          nit: objectClientes[i][2],
          dui: objectClientes[i][3],
        };
        await this.crearClienteCSV(cliente);
        cont++;
      }

      if (objectClientes.length - 1 == cont) {
        this.overlay = false;
        this.listar();
      }
    },
    async crearClienteCSV(cliente) {
      let aux_tipo_persona = "";
      let aux_codigo = "";
      if (cliente.nit) {
        aux_tipo_persona = "PERSONA JURIDICA";
        aux_codigo = cliente.nit;
      } else {
        aux_tipo_persona = "PERSONA NATURAL";
        aux_codigo = cliente.dui;
      }

      await axios.post("api/Clientes/Crear", {
        id_departamentos: 3,
        id_municipios: 1,
        tipo_persona: aux_tipo_persona,
        codigo: aux_codigo,
        fecha_ingreso: "2023-01-01",
        nombre: cliente.nombre,
        nombre_carpeta: cliente.nombre_carpeta,
        razon_social: "",
        direccion: "",
        fecha_nacimiento: "2023-01-01",
        dui: cliente.dui,
        nit: cliente.nit,
        celular: "",
        telefono: "",
        correo: "",
        giro: "",
        tamaño: "",
        registro: "",
        contacto1: "",
        contacto2: "",
        contacto3: "",
        telefono1: "",
        telefono2: "",
        telefono3: "",
        nombre_RL: "",
        dui_Rl: "",
        ppe_RL: false,
      });
    },
    ////////////////////////////////////////////////////////////////////
    //REPRESNETANTE LEGAL
    adicionarRepreLegal(item) {
      //console.log(item);
      this.id_cliente = item.id_cliente;
      this.nombre_RL = item.nombre_RL;
      this.dui_RL = item.dui_RL;
      this.ppe_RL = item.ppe_RL;
      this.representante_legal = true;
    },
    agregarRL() {
      axios
        .put("api/Clientes/ActualizarRL", {
          id_cliente: this.id_cliente,
          nombre_RL: this.nombre_RL,
          dui_RL: this.dui_RL,
          ppe_RL: this.ppe_RL,
        })
        .then(() => {
          this.representante_legal = false;
          this.listar();
        });
    },
  },
};
</script>

<style>
.lineaMargen {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 5px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 3px;
}
</style>