<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Categoria de seguros</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()" v-if="false"> Nuevo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                far fa-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="codigo"
              label="Código"
              outlined
              dense
              :rules="rules"
            ></v-text-field>
            <v-text-field
              v-model="descripcion"
              label="Descripción"
              outlined
              dense
              :rules="rules"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            v-if="bandera == 1"
            @click="crearCategoriaSeguros()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera == 2"
            @click="actualizarCategoriaSeguros()"
            >ACTUALIZAR</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <lottie :estado="overlay"/>
  </v-row>
</template>

<script>
import axios from "axios";
import lottie from "../../LottieFiles/Animaciones.vue"

export default {
  data: () => ({
    headers: [
      { text: "Código", value: "codigo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Opciones", value: "actions", align: "center", sorteable: false },
    ],
    items: [],
    dialog: false,
    id_categoria_seguros: null,
    codigo: null,
    descripcion: null,
    rules: [(v) => !!v || "El campo es obligatorio"],
    bandera: -1,
    overlay: false,
  }),
  components: {
    lottie
  },
  created() {
    this.listar();
  },
  methods: {
    listar() {
      this.overlay = true;
      axios
        .get("api/CategoriaSeguros/Listar")
        .then((response) => {
          this.items = response.data;
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    abrirDialog() {
      this.bandera = 1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    editarItem(item) {
      this.id_categoria_seguros = item.id_categoria_seguros;
      this.codigo = item.codigo;
      this.descripcion = item.descripcion;

      this.bandera = 2;
      this.dialog = true;
    },
    limpiar() {
      this.id_categoria_seguros = null;
      this.codigo = null;
      this.descripcion = null;

      this.$refs.form.resetValidation();
    },
    crearCategoriaSeguros() {
      this.overlay = true;
      axios
        .post("api/CategoriaSeguros/Crear", {
          codigo: this.codigo,
          descripcion: this.descripcion,
        })
        .then(() => {
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro creado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarCategoriaSeguros() {
      this.overlay = true;
      axios
        .put("api/CategoriaSeguros/Actualizar", {
          id_categoria_seguros: this.id_categoria_seguros,
          codigo: this.codigo,
          descripcion: this.descripcion,
        })
        .then(() => {
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro actualizado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>