<template>
  <v-row class="fill-height">
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-3"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Productos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              label="Busqueda"
              single-line
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <v-chip v-if="item.activo" dark color="green">
            <span>SI</span>
          </v-chip>
          <v-chip v-else dark color="red">
            <span>NO</span>
          </v-chip>
        </template>
        <template v-slot:[`item.exento`]="{ item }">
          <v-chip v-if="item.exento" dark color="green">
            <span>SI</span>
          </v-chip>
          <v-chip v-else dark color="red">
            <span>NO</span>
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                far fa-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="800" persistent>
      <v-card>
        <v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="auto" class="pr-3">
              <v-checkbox
                v-model="activo"
                label="Activo"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-model="exento"
                label="Exento"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="codigo"
                  label="Código"
                  outlined
                  dense
                  readonly
                  hide-details
                  :rules="rules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pt-3 pr-3">
                <v-autocomplete
                  v-model="id_aseguradora"
                  label="Aseguradora"
                  :items="items_aseguradora"
                  item-value="id_aseguradoras"
                  item-text="descripcion"
                  outlined
                  dense
                  :rules="rules"
                  hide-details
                  return-object
                  @change="construirCodigo"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pt-3">
                <v-autocomplete
                  v-model="id_categoria_seguros"
                  label="Categoria"
                  :items="items_categoria_seguros"
                  item-value="id_categoria_seguros"
                  item-text="descripcion"
                  outlined
                  dense
                  :rules="rules"
                  hide-details
                  return-object
                  @change="construirCodigo"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pt-3 pr-3">
                <v-autocomplete
                  v-model="id_ramo_seguros"
                  label="Ramo"
                  :items="items_ramo_seguros"
                  item-value="id_ramo_seguros"
                  item-text="descripcion"
                  outlined
                  dense
                  :rules="rules"
                  hide-details
                  return-object
                  @change="construirCodigo"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pt-3">
                <v-autocomplete
                  v-model="id_tipo"
                  label="Tipo"
                  :items="items_tipo"
                  item-value="id_tipo_seguro"
                  item-text="descripcion"
                  outlined
                  dense
                  :rules="rules"
                  hide-details
                  return-object
                  @change="construirCodigo"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pt-3">
                <v-text-field
                  v-model="nombre_servicio"
                  label="Nombre"
                  outlined
                  dense
                  :rules="rules"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-3">
                <v-textarea
                  v-model="descripcion"
                  label="Descripción"
                  outlined
                  dense
                  auto-grow
                  row-height="10"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="bandera == 1" @click="crearSeguro()"
            >GUARDAR</v-btn
          >
          <v-btn color="primary" v-if="bandera == 2" @click="actualizarSeguro()"
            >ACTUALIZAR</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <lottie :estado="overlay" />
  </v-row>
</template>

<script>
import axios from "axios";
import lottie from "../../LottieFiles/Animaciones.vue";

export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Código", value: "codigo" },
      { text: "Nombre servicio", value: "nombre" },
      { text: "Aseguradora", value: "nombre_aseguradora" },
      { text: "Categoria", value: "nombre_categoria_seguro" },
      { text: "Ramo", value: "nombre_ramo_seguro" },
      { text: "Activo", value: "activo" },
      { text: "Exento", value: "exento" },
      { text: "Opciones", value: "actions", align: "center", sorteable: false },
    ],
    items: [],
    dialog: false,
    id_seguros: {},
    id_aseguradora: {},
    id_categoria_seguros: {},
    id_ramo_seguros: {},
    id_tipo: {},
    codigo: null,
    nombre_servicio: null,
    descripcion: null,
    activo: true,
    exento: false,
    rules: [(v) => !!v || "El campo es obligatorio"],
    items_aseguradora: [],
    items_categoria_seguros: [],
    items_ramo_seguros: [],
    items_tipo: [],
    bandera: -1,
    overlay: false,
  }),
  created() {
    this.listar();
    this.litarAseguradoras();
    this.listarCategoriaSeguros();
    this.listarRamoSeguros();
    this.listarTipoSeguros();
  },
  components: {
    lottie,
  },
  methods: {
    listar() {
      this.overlay = true;
      axios
        .get("api/Seguros/Listar")
        .then((response) => {
          this.items = response.data;
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    litarAseguradoras() {
      axios
        .get("api/Aseguradoras/Listar")
        .then((response) => {
          this.items_aseguradora = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarCategoriaSeguros() {
      axios
        .get("api/CategoriaSeguros/Listar")
        .then((response) => {
          this.items_categoria_seguros = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarRamoSeguros() {
      axios
        .get("api/RamoSeguros/Listar")
        .then((response) => {
          this.items_ramo_seguros = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarTipoSeguros() {
      axios
        .get("api/TipoSeguros/Listar")
        .then((response) => {
          this.items_tipo = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirDialog() {
      this.bandera = 1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    editarItem(item) {
      var index_a = this.items_aseguradora.findIndex(
        (a) => a.id_aseguradoras == item.id_aseguradoras
      );
      var index_c = this.items_categoria_seguros.findIndex(
        (a) => a.id_categoria_seguros == item.id_categoria_seguros
      );
      var index_r = this.items_ramo_seguros.findIndex(
        (a) => a.id_ramo_seguros == item.id_ramo_seguros
      );
      var index_t = this.items_tipo.findIndex(
        (a) => a.id_tipo_seguro == item.id_tipo_seguros
      );

      this.id_seguros = item.id_seguros;
      this.id_aseguradora = this.items_aseguradora[index_a];
      this.id_categoria_seguros = this.items_categoria_seguros[index_c];
      this.id_ramo_seguros = this.items_ramo_seguros[index_r];
      this.id_tipo = this.items_tipo[index_t];
      this.codigo = item.codigo;
      this.nombre_servicio = item.nombre;
      this.descripcion = item.descripcion;
      this.activo = item.activo;
      this.exento = item.exento;

      this.bandera = 2;
      this.dialog = true;
    },
    limpiar() {
      this.id_aseguradora = "";
      this.id_categoria_seguros = "";
      this.id_ramo_seguros = "";
      this.id_tipo = "";
      this.codigo = null;
      this.nombre_servicio = null;
      this.descripcion = null;
      this.activo = true;
      this.exento = false;

      this.$refs.form.resetValidation();
    },
    construirCodigo() {
      if (
        this.id_aseguradora.id_aseguradoras &&
        this.id_categoria_seguros.id_categoria_seguros &&
        this.id_ramo_seguros.id_ramo_seguros &&
        this.id_tipo.id_tipo_seguro
      ) {
        this.codigo =
          this.id_aseguradora.codigo +
          this.id_categoria_seguros.codigo +
          this.id_ramo_seguros.codigo +
          this.id_tipo.codigo;

        this.construirNombre();
      }
    },
    construirNombre() {
      this.nombre_servicio =
        "SEGURO DE " +
        this.id_ramo_seguros.descripcion +
        " " +
        this.id_tipo.descripcion;
    },
    crearSeguro() {
      if (
        this.codigo == null ||
        this.id_aseguradora == null ||
        this.id_categoria_seguros == null ||
        this.id_ramo_seguros == null ||
        this.id_tipo == null ||
        this.nombre_servicio == null
      ) {
        this.alerta("info", "Complete los campos obligatorios");
        this.$refs.form.validate();
        return;
      }
      this.overlay = true;
      axios
        .post("api/Seguros/Crear", {
          id_aseguradoras: this.id_aseguradora.id_aseguradoras,
          id_categoria_seguros: this.id_categoria_seguros.id_categoria_seguros,
          id_ramo_seguros: this.id_ramo_seguros.id_ramo_seguros,
          id_tipo_seguros: this.id_tipo.id_tipo_seguro,
          codigo: this.codigo,
          nombre: this.nombre_servicio,
          descripcion: this.descripcion,
          activo: this.activo,
          exento: this.exento,
        })
        .then(() => {
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro creado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    actualizarSeguro() {
      if (
        this.codigo == null ||
        this.id_aseguradora == null ||
        this.id_categoria_seguros == null ||
        this.id_ramo_seguros == null ||
        this.id_tipo == null ||
        this.nombre_servicio == null
      ) {
        this.alerta("info", "Complete los campos obligatorios");
        this.$refs.form.validate();
        return;
      }
      this.overlay = true;
      axios
        .put("api/Seguros/Actualizar", {
          id_seguros: this.id_seguros,
          id_aseguradoras: this.id_aseguradora.id_aseguradoras,
          id_categoria_seguros: this.id_categoria_seguros.id_categoria_seguros,
          id_ramo_seguros: this.id_ramo_seguros.id_ramo_seguros,
          id_tipo_seguros: this.id_tipo.id_tipo_seguro,
          codigo: this.codigo,
          nombre: this.nombre_servicio,
          descripcion: this.descripcion,
          activo: this.activo,
          exento: this.exento,
        })
        .then(() => {
          this.dialog = false;
          this.limpiar();
          this.listar();
          this.alerta("success", "Registro actualizado con exito");
          this.overlay = false;
        })
        .catch(() => {
          this.overlay = false;
          this.alerta("error", "Sucedio algún error");
        });
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>