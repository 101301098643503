<template>
  <div>
    <v-overlay v-model="estado" z-index="0">
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="https://assets2.lottiefiles.com/private_files/lf30_owwwnvjg.json"
        style="width: 320px"
        background="trasnparent"
      >
      </lottie-player>
    </v-overlay>
  </div>
</template>

<script>
import "@lottiefiles/lottie-player";

export default {
  data: () => ({
    overlay: false,
  }),
  props: ["estado"],
};
</script>