<template>
  <v-row justify="center" align="center">
    <v-col cols="auto">
      <v-card width="500">
        <v-toolbar color="primary" dark elevation="0">
          <v-card-title class="mx-auto">INICIO DE SESION</v-card-title>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="usuario"
            :rules="rules"
            label="Usuario"
            outlined
            dense
            prepend-icon="fa-solif fa-user"
          ></v-text-field>
          <v-text-field
            v-model="contraseña"
            :rules="rules"
            label="Contraseña"
            outlined
            dense
            prepend-icon="fa-solif fa-unlock"
            :append-icon="
              verContraseña ? 'fa-solif fa-eye' : 'fa-solid fa-eye-slash'
            "
            @click:append="verContraseña = !verContraseña"
            :type="verContraseña ? 'text' : 'password'"
            @keyup.enter="iniciarSesion()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="iniciarSesion()">INGRESAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <lottie :estado="overlay" />
  </v-row>
</template>

<script>
import axios from "axios";
import router from "@/router";
import lottie from "../../LottieFiles/Animaciones.vue";
import apiLocal from "../../AccesosToken/Api";

export default {
  data: () => ({
    usuario: null,
    contraseña: null,
    rules: [(v) => !!v || "El campo es obligatorio"],
    verContraseña: false,
    codigo: null,
    overlay: false,
    token: "",
  }),
  components: {
    lottie,
  },
  methods: {
    iniciarSesion() {
      if (this.usuario && this.contraseña) {
        this.overlay = true;
        axios
          .post("api/Usuarios/Login", {
            usuario: this.usuario,
            password: this.contraseña,
          })
          .then((response) => {
            this.overlay = false;
            this.alerta("success", "Inicio de sesion exitosa");
            this.$store.dispatch("guardarToken", response.data.token);
            this.token = response.data;
            this.validarDatos();
          })
          .catch(() => {
            this.overlay = false;
            this.alerta(
              "error",
              "No se pudo inciar sesion, verifique su usuario o contraseña"
            );
          });
      } else {
        this.$refs.form.validate();
        this.alerta("info", "Los campos son obligatorios");
      }
    },
    validarDatos() {
      axios
        .get("api/Seguridades/Listar/" + this.$store.state.usuario.id_usuario)
        .then(async (response) => {
          const sesion = await apiLocal.metodos.getItemObject("sesion", response.data);
          
          if (sesion == null || sesion == '' || sesion == undefined) {
            this.guardarToken("sesion", this.token);
          } else {
            var obj = apiLocal.metodos.getItemObject("sesion", response.data);
            localStorage.setItem("sesion", obj.codigo);
            router.push({ name: "bienvenida" }).catch(() => {});
          }
        });
    },
    guardarToken(clave, valor) {
      axios
        .post("api/Seguridades/Crear", {
          id_usuario: this.$store.state.usuario.id_usuario,
          codigo: this.generarCode(),
          clave: clave,
          valor: apiLocal.metodos.encriptar(valor.token),
        })
        .then(() => {
          router.push({ name: "bienvenida" }).catch(() => {});
        })
        .catch((error) => {
          this.alerta("error", "Sucedio algún error");
        });
    },
    generarCode() {
      this.codigo = window.crypto
        .getRandomValues(new BigUint64Array(4))
        .reduce(
          (prev, curr, index) =>
            (!index ? prev : prev.toString(36)) +
            (index % 2 ? curr.toString(36).toUpperCase() : curr.toString(36))
        )
        .split("")
        .sort(() => 128 - window.crypto.getRandomValues(new Uint8Array(1))[0])
        .join("");

      localStorage.setItem("sesion", this.codigo);

      return this.codigo;
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>