import axios from "axios"
import { saveAs } from 'file-saver';
import JsZip from "jszip";
import apiLocal from "../AccesosToken/Api"

export default ({
    data: () => ({
        blob: "",
    }),
    metodos: {
        async AutenticarUsuario() {
            let client_id = process.env.VUE_APP_ID_ONE_DRIVE;

            let scope = "files.readwrite.all offline_access";
            let redirect_uri = process.env.VUE_APP_URL_ONE_DRIVE;

            var obj = {
                clientId: client_id,
                scopes: scope,
                redirectUri: redirect_uri
            }
            var appInfo = Object.assign({}, obj);
            appInfo.redirectUri = appInfo.redirectUri.replace(/(#|$)/, '');

            var url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize" +
                "?client_id=" + appInfo.clientId +
                "&scope=" + encodeURIComponent(appInfo.scopes) +
                "&response_type=code" +
                "&redirect_uri=" + encodeURIComponent(appInfo.redirectUri);
            window.open(url, "_blank").focus();
        },

        async CrearArchivoEstadoCuenta(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, extencion, archivo) {
            let year = año.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let refresh_token = await apiLocal.metodos.getItemObject("refresh_token", credenciales);
            let nuevo_token = await apiLocal.metodos.getTokenRefresh(refresh_token.valor);

            let header = { Authorization: "Bearer " + nuevo_token.data.access_token, 'Content-Type': archivo.type };
            let configuracion = { headers: header };

            const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/" + "ESTADO CUENTA" + "/" + numPoliza + "_" + nombre_cliente.trim() + extencion + ":/content",
                archivo
                , configuracion);
            return data;
        },

        async CrearArchivosCliente(id_usuario, archivo, nombre_cliente) {
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/Documentos personales" + "/" + archivo.name + ":/content",
                    archivo
                    , configuracion);
                return data;
            }
        },

        async CrearArchivoPoliza(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, tipo_doc, archivo) {
            let year = año.split("-");

            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/" + tipo_doc.trim() + "/" + archivo.name + ":/content",
                    archivo
                    , configuracion);
                return data;
            }
        },

        async CrearArchivoPolizaCuota(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, fecha, tipoDoc, archivo) {
            let year = año.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/" + tipoDoc.trim() + "/" + archivo.name + ":/content",
                    archivo,
                    configuracion);
                return data;
            }
        },
        async CrearArchivoPagoPolizaCuota(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, fecha, tipoDoc, archivo) {
            let date = new Date(año);
            let year = date.getFullYear();
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year.toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/" + tipoDoc.trim() + "/" + archivo.name + ":/content",
                    archivo,
                    configuracion);
                return data;
            }
        },

        async CrearArchivoCuotaAumentosInclusiones(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, tipoDoc, archivo) {
            let year = date.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/Aumentos o inclusiones/" + archivo.name + ":/content",
                    archivo,
                    configuracion);
                return data;
            }
        },
        async CrearArchivoPagoCuotaAumentosInclusiones(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, tipoDoc, archivo) {
            let year = date.getFullYear();
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/Aumentos o inclusiones/" + archivo.name + ":/content",
                    archivo,
                    configuracion);
                return data;
            }
        },

        async CrearArchivoCertificado(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, fecha, tipoDoc, archivo) {
            let year = año.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/" + tipoDoc.trim() + "/" + archivo.name + ":/content",
                    archivo,
                    configuracion);
                return data;
            }
        },
        async CrearArchivoPagoCertificado(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, fecha, tipoDoc, archivo) {
            let year = año.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/" + tipoDoc.trim() + "/" + archivo.name + ":/content",
                    archivo,
                    configuracion);
                return data;
            }
        },
        async CrearArchivoCertificadoArchivo(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, numPoliza, tipo_doc, archivo) {
            let year = año.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + numPoliza.trim() + "/" + tipo_doc.trim() + "/" + archivo.name + ":/content",
                    archivo
                    , configuracion);
                return data;
            }
        },

        async CrearArchivoReclamo(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, poliza, nombre_carpeta, archivo) {
            let year = año.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + poliza.trim() + "/RECLAMOS/" + nombre_carpeta + "/" + archivo.name + ":/content",
                    archivo
                    , configuracion);
                return data;
            }
        },

        async CrearArchivoTransporteMercaderia(id_usuario, nombre_cliente, aseguradora, año, categoria, ramo, poliza, correlativo, archivo) {
            let year = año.split("-");
            let newAseguradora = aseguradora.split(/[.,]+/).join("");
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, 'Content-Type': archivo.type };
                let configuracion = { headers: header };

                const data = await axios.put("https://graph.microsoft.com/v1.0/me/drive/root:/" + process.env.VUE_APP_RAIZ_CARPETA + "/1-Asegurados/" +
                    nombre_cliente.trim() + "/" + year[0].toString() + "/" + newAseguradora.trim() + "/" + categoria.trim() + "/" + ramo.trim() + "/" + poliza.trim() + "/TRANS MERCADERIA/" + correlativo + "/" + archivo.name + ":/content",
                    archivo
                    , configuracion);
                return data;
            }
        },

        async verListaArchivos(id_usuario, ruta) {
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt, "Content-type": "application/json" };
                let configuracion = { headers: header };

                let result = await axios.get("https://graph.microsoft.com/v1.0/me/drive/root:/" + ruta + ":/children", configuracion)
                    .catch((error) => {
                        return error.response
                    });

                if (result.status == 404) {
                    return result.statusText;
                } else {
                    return result.data.value
                }
            }
        },
        async VerArchivo(id_usuario, id_one_drive) {
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let refresh_token = await apiLocal.metodos.getItemObject("refresh_token", credenciales);
            let nuevo_token = await apiLocal.metodos.getTokenRefresh(refresh_token.valor);

            let header = { Authorization: "Bearer " + nuevo_token.data.access_token };
            let configuracion = { headers: header };

            axios
                .get(
                    "https://graph.microsoft.com/v1.0/me/drive/items/" + id_one_drive,
                    configuracion
                )
                .then((response) => {
                    window.open(response.data.webUrl, "_blank").focus();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async DescargarItems(id_usuario, archivos = []) {
            var contador = 0;
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);
            var zip = new JsZip();

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt };
                archivos.forEach((element) => {
                    axios({
                        url:
                            "https://graph.microsoft.com/v1.0/me/drive/items/" + element.id + "/content",
                        method: "GET",
                        responseType: "blob",
                        headers: header,
                    })
                        .then((response) => {
                            contador += 1;

                            this.blob = new Blob([response.data]);
                            zip.file(element.name, this.blob);

                            if (contador == archivos.length) {
                                zip.generateAsync({ type: "blob" }).then((blob) => {
                                    saveAs(blob, "Archivos.zip");
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });
            }
        },
        async DescargarArchivo(id_usuario, archivos = []) {
            var contador = 0;
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);
            var zip = new JsZip();

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt };
                archivos.forEach((element) => {
                    axios({
                        url:
                            "https://graph.microsoft.com/v1.0/me/drive/items/" + element.id + "/content",
                        method: "GET",
                        responseType: "blob",
                        headers: header,
                    })
                        .then((response) => {
                            contador += 1;

                            this.blob = new Blob([response.data]);
                            zip.file(element.name, this.blob);

                            if (contador == archivos.length) {
                                zip.generateAsync({ type: "blob" }).then((blob) => {
                                    saveAs(blob, "Archivos.zip");
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });
            }
        },
        async EliminarArchivo(id_usuario, id_one_drive) {
            let credenciales = await apiLocal.metodos.listarClavesOneDrive(id_usuario);
            let access_token = await apiLocal.metodos.getItemObject("access_token", credenciales);

            if (access_token != undefined || access_token != null || access_token != '') {
                let token_decrypt = await apiLocal.metodos.desencriptar(access_token.valor);
                let header = { Authorization: "Bearer " + token_decrypt };
                let configuracion = { headers: header };

                const data = await axios.delete("https://graph.microsoft.com/v1.0/me/drive/items/" + id_one_drive,
                    configuracion
                );
                return data.status;
            }
        }
    }
})