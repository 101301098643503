<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-data-table :headers="headers" :items="items" class="elevation-3 ma-3">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="blue" dark @click="cerrarSesion()" v-if="false"
              >Cerrar Sesion one drive</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="editarItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                far fa-edit
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.condicion`]="{ item }">
          <v-chip :color="colorEstado(item.condicion)" dark>
            <div v-if="item.condicion"><span>Activo</span></div>
            <div v-else><span>Inactivo</span></div>
          </v-chip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="nombre"
                  label="Nombre"
                  outlined
                  dense
                  :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="usuario"
                  label="Usuario"
                  outlined
                  dense
                  :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-autocomplete
                  v-model="id_rol"
                  :items="items_rol"
                  item-value="rol_id"
                  item-text="nombre"
                  label="Rol"
                  outlined
                  dense
                  :rules="rules"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model="contraseña"
                  label="Contraseña"
                  outlined
                  dense
                  :rules="rules"
                  :append-icon="
                    verContraseña ? 'fa-solif fa-eye' : 'fa-solid fa-eye-slash'
                  "
                  @click:append="verContraseña = !verContraseña"
                  :type="verContraseña ? 'text' : 'password'"
                  :hint="
                    bandera == 2 ? 'La contraseña se mostrara encriptada' : ''
                  "
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-card elevation="0">
                  <v-list dense class="py-0">
                    <v-list-item class="pa-0">
                      <v-icon small class="pr-1">fa-solid fa-minus</v-icon>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title :class="minimoCaracteres"
                          >Mínimo 6 caracteres</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0">
                      <v-icon small class="pr-1">fa-solid fa-minus</v-icon>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title :class="caracterMayuscula"
                          >Un carácter en mayúscula</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0">
                      <v-icon small class="pr-1">fa-solid fa-minus</v-icon>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title :class="caracterEspecial"
                          >Un carácter especial</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item class="pa-0">
                      <v-icon small class="pr-1">fa-solid fa-minus</v-icon>
                      <v-list-item-content class="pa-0">
                        <v-list-item-title :class="unNumero"
                          >Un número</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="auto" class="py-0">
                <v-checkbox
                  v-model="estado"
                  label="Activo"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="auto" class="ml-3 py-0" v-if="false">
                <v-checkbox
                  v-model="super_usuario"
                  label="Super usuario"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="bandera == 1" @click="crearUsuario()"
            >GUARDAR</v-btn
          >
          <v-btn
            color="primary"
            v-if="bandera == 2"
            @click="actualizarUsuario()"
            >ACTUALIZAR</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()">CERRAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import apiOneDrive from "../../ApiOneDrive/API";

export default {
  data: () => ({
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Usuario", value: "usuario" },
      { text: "Rol", value: "nombre_rol" },
      { text: "Estado", value: "condicion" },
      { text: "Opciones", value: "actions", align: "center" },
    ],
    items: [],
    dialog: false,
    id_usuario: null,
    id_rol: "",
    nombre: "",
    usuario: "",
    contraseña: "",
    passwordAnt: null,
    rol: null,
    estado: true,
    super_usuario: false,
    items_rol: [],
    rules: [(v) => !!v || "El campo es obligatorio"],
    bandera: -1,
    verContraseña: false,
  }),
  created() {
    this.listar();
    this.listarRoles();
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    minimoCaracteres() {
      if (this.contraseña.length >= 6) {
        return "green--text";
      }
    },
    caracterMayuscula() {
      let i = 0;
      var format_uppCase = /^[A-Z]*$/;
      var letter_mayus = false;

      while (i < this.contraseña.length) {
        let letra = this.contraseña.charAt(i);
        if (format_uppCase.test(letra)) {
          letter_mayus = true;
        }
        i++;
      }

      if (letter_mayus) {
        return "green--text";
      }
    },
    caracterEspecial() {
      var format_special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

      if (format_special.test(this.contraseña)) {
        return "green--text";
      }
    },
    unNumero() {
      let j = 0;
      var letter_num = false;
      var format_number = /^\d$/;

      while (j < this.contraseña.length) {
        let letra = this.contraseña.charAt(j);
        if (format_number.test(letra)) {
          letter_num = true;
        }
        j++;
      }

      if (letter_num) {
        return "green--text";
      }
    }
  },
  methods: {
    listar() {
      axios
        .get("api/usuarios/Listar")
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarRoles() {
      axios
        .get("api/Roles/Listar")
        .then((response) => {
          this.items_rol = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirDialog() {
      this.bandera = 1;
      this.dialog = true;
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    editarItem(item) {
      this.id_usuario = item.id_usuario;
      this.id_usuario = item.id_usuario;
      this.id_rol = item.id_rol;
      this.nombre = item.nombre;
      this.usuario = item.usuario;
      this.contraseña = item.password_hash;
      this.passwordAnt = item.password_hash;
      this.estado = item.condicion;
      this.super_usuario = item.super_usuario;

      this.bandera = 2;
      this.dialog = true;
    },
    limpiar() {
      this.id_usuario = null;
      this.id_rol = "";
      this.nombre = "";
      this.usuario = "";
      this.contraseña = "";
      this.estado = false;
      this.super_usuario = false;
      this.passwordAnt = "";
      this.actPassword = false;

      this.$refs.form.resetValidation();
    },
    crearUsuario() {
      let i = 0;
      let j = 0;
      var format_special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      var format_number = /^\d$/;
      var format_uppCase = /^[A-Z]*$/;
      var letter_mayus = false;
      var letter_num = false;

      //CARACTER ESPECIAL
      if (!format_special.test(this.contraseña)) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (caracter especial)"
        );
      }

      if (this.contraseña.length < 6) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (muy corta)"
        );
      }

      while (j < this.contraseña.length) {
        let letra = this.contraseña.charAt(j);
        if (format_number.test(letra)) {
          letter_num = true;
        }
        j++;
      }

      if (!letter_num) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (falta un numero)"
        );
      }

      while (i < this.contraseña.length) {
        let letra = this.contraseña.charAt(i);
        if (format_uppCase.test(letra)) {
          letter_mayus = true;
        }
        i++;
      }

      if (!letter_mayus) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (falta una letra mayuscula)"
        );
      }

      if (this.nombre == "" || this.usuario == "" || this.id_rol == "") {
        return this.alerta("info", "Debe de llenar todos los campos.");
      }

      Promise.all([this.verificarUsuario()]).then((response) => {
        if (response[0].data == 0) {
          axios
            .post("api/Usuarios/Crear", {
              id_rol: this.id_rol,
              nombre: this.nombre,
              usuario: this.usuario,
              password: this.contraseña,
              condicion: this.estado,
              super_usuario: this.super_usuario,
            })
            .then(() => {
              this.cerrarDialog();
              this.listar();
              this.alerta("success", "Registro creado con exito");
            })
            .catch((error) => {
              console.log(error);
              this.alerta("error", "Sucedio algún error");
            });
        } else {
          this.alerta("info", "Ya existe nombre de usuario.");
        }
      });
    },
    actualizarUsuario() {
      let i = 0;
      let j = 0;
      var format_special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      var format_number = /^\d$/;
      var format_uppCase = /^[A-Z]*$/;
      var letter_mayus = false;
      var letter_num = false;

      if (this.contraseña != this.passwordAnt) {
        this.actPassword = true;

        if (!format_special.test(this.contraseña)) {
          return this.alerta(
            "info",
            "La contraseña no cumple los requisitos. (caracter especial)"
          );
        }

        if (this.contraseña.length < 8) {
          return this.alerta(
            "info",
            "La contraseña no cumple los requisitos. (muy corta)"
          );
        }

        while (j < this.contraseña.length) {
          let letra = this.contraseña.charAt(j);
          if (format_number.test(letra)) {
            letter_num = true;
          }
          j++;
        }

        if (!letter_num) {
          return this.alerta(
            "info",
            "La contraseña no cumple los requisitos. (falta un numero)"
          );
        }

        while (i < this.contraseña.length) {
          let letra = this.contraseña.charAt(i);
          if (format_uppCase.test(letra)) {
            letter_mayus = true;
          }
          i++;
        }

        if (!letter_mayus) {
          return this.alerta(
            "info",
            "La contraseña no cumple los requisitos. (falta una letra mayuscula)"
          );
        }
      }

      if (this.nombre == "" || this.usuario == "" || this.id_rol == "") {
        return this.alerta("info", "Debe de llenar todos los campos.");
      }

      axios
        .put("api/Usuarios/Actualizar", {
          id_usuario: this.id_usuario,
          id_rol: this.id_rol,
          nombre: this.nombre,
          usuario: this.usuario,
          password: this.contraseña,
          condicion: this.estado,
          super_usuario: this.super_usuario,
          act_password: this.actPassword,
        })
        .then(() => {
          this.cerrarDialog();
          this.listar();
          this.alerta("success", "Registro actualizado con exito");
        })
        .catch((error) => {
          console.log(error);
          this.alerta("error", "Sucedio algun error");
        });
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
    colorEstado(item) {
      if (item) {
        return "green";
      } else {
        return "red";
      }
    },
    cerrarSesion() {
      apiOneDrive.metodos.cerrarSesion();
    },
    verificarUsuario() {
      return axios.get("api/Usuarios/VerificarUsuario/" + this.usuario);
    },
  },
};
</script>