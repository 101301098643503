<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="auto">
        <h1>Cambiar contraseña</h1>
      </v-col>
      <v-col cols="12" class="text-xl--h4">
        <div class="headline font-weight-bold">Mi usuario</div>
        <div class="subtitle-1">{{ this.$store.state.usuario.nombre }}</div>
      </v-col>
      <v-col cols="auto">
        <v-card elevation="3">
          <v-card-text>
            <v-text-field
              v-model="contraseña"
              label="Nueva contraseña"
              outlined
              dense
              hide-details
              :append-icon="
                flag_icon ? 'fa-solid fa-eye' : 'fa-regular fa-eye-slash'
              "
              :type="flag_icon ? 'text' : 'password'"
              @click:append="ocultarCampo"
            ></v-text-field>
            <v-list dense>
              <v-list-item v-for="(item, i) in items_req" :key="i" class="pl-0">
                <v-list-item-icon class="mr-1">
                  <v-icon size="18" class="mx-auto">
                    fa-solid fa-circle
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ item.titulo }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="actualizarContraseña()"
              >Actualizar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    contraseña: "",
    flag_icon: false,
    items_req: [
      { titulo: "Minimo 6 caracteres" },
      { titulo: "Un carácter en mayúscula" },
      { titulo: "Un carácter especial" },
      { titulo: "Un número" },
    ],
  }),
  methods: {
    actualizarContraseña() {
      let i = 0;
      let j = 0;
      var format_special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      var format_number = /^\d$/;
      var format_uppCase = /^[A-Z]*$/;
      var letter_mayus = false;
      var letter_num = false;

      if (this.contraseña == "") {
        return this.alerta("info", "Debe de colocar una contraseña.");
      }

      //CARACTER ESPECIAL
      if (!format_special.test(this.contraseña)) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (caracter especial)"
        );
      }

      if (this.contraseña.length < 6) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (muy corta)"
        );
      }

      while (j < this.contraseña.length) {
        let letra = this.contraseña.charAt(j);
        if (format_number.test(letra)) {
          letter_num = true;
        }
        j++;
      }

      if (!letter_num) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (falta un numero)"
        );
      }

      while (i < this.contraseña.length) {
        let letra = this.contraseña.charAt(i);
        if (format_uppCase.test(letra)) {
          letter_mayus = true;
        }
        i++;
      }

      if (!letter_mayus) {
        return this.alerta(
          "info",
          "La contraseña no cumple los requisitos. (falta una letra mayuscula)"
        );
      }

      axios
        .put("api/Usuarios/ActualizarContraseña", {
          id_usuario: this.$store.state.usuario.id_usuario,
          password: this.contraseña,
        })
        .then(() => {
          this.alerta("success", "Contraseña actualizada");
          this.contraseña = "";
        })
        .catch(() => {
          this.alerta("error", "Contraseña no pudo actualizarse");
        });
    },
    ocultarCampo() {
      this.flag_icon = !this.flag_icon;
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>