<template>
  <v-row>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" class="elevation-3">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Planes</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-menu
              v-model="menu_search"
              :close-on-content-click="false"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>fas fa-search</v-icon>
                </v-btn>
              </template>
              <v-card width="400">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="search_aseguradora"
                        class="mx-1"
                        label="Aseguradoras"
                        :items="items_aseguradoras"
                        item-text="descripcion"
                        item-value="id_aseguradoras"
                        @change="listarPlanes()"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="search_categoria"
                        class="mx-1"
                        label="Categorias"
                        :items="items_categoria"
                        item-text="descripcion"
                        item-value="id_categoria_seguros"
                        @change="listarPlanes()"
                        outlined
                        dense
                        hide-details
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="search_ramo"
                        class="mx-1"
                        label="Ramos"
                        :items="items_ramos"
                        item-text="descripcion"
                        item-value="id_ramo_seguros"
                        @change="listarPlanes()"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="abrirDialog()"> Nuevo </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.event_edit`]="{ item }">
          <v-icon @click="editarItem(item)"> fas fa-edit </v-icon>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" width="600" persistent scrollable>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="pt-1">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="id_aseguradora"
                label="Aseguradora"
                :items="items_aseguradoras"
                item-text="descripcion"
                item-value="id_aseguradoras"
                return-object
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="id_categoria"
                label="Categoria"
                :items="items_categoria"
                item-text="descripcion"
                item-value="id_categoria_seguros"
                return-object
                outlined
                dense
                hide-details
                @change="listarComplementosPlanes()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="id_ramo"
                label="Ramo"
                :items="items_ramos"
                item-text="descripcion"
                item-value="id_ramo_seguros"
                return-object
                outlined
                dense
                hide-details
                @change="listarComplementosPlanes()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre_plan"
                label="Nombre del plan"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="comision_inicial"
                label="% Comisión inicial"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="comision_renovacion"
                label="% Comisión renovación"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" @click="addPlan()">Agregar</v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers_planes_estatus"
                :items="items_planes_estatus"
              >
                <template v-slot:[`item.nombre_plan`]="{ item }">
                  <span v-if="item.id_planes">{{ item.nombre_plan }}</span>
                  <v-edit-dialog
                    v-else
                    :return-value.sync="item.nombre_plan"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.nombre_plan }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.nombre_plan"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.comision_inicial`]="{ item }">
                  <span v-if="item.id_planes">{{ item.comision_inicial }}</span>
                  <v-edit-dialog
                    v-else
                    :return-value.sync="item.comision_inicial"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.comision_inicial }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.comision_inicial"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.comision_renovacion`]="{ item }">
                  <span v-if="item.id_planes">{{
                    item.comision_renovacion
                  }}</span>
                  <v-edit-dialog
                    v-else
                    :return-value.sync="item.comision_renovacion"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.comision_renovacion }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.comision_renovacion"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.event_delete`]="{ item }">
                  <v-icon v-if="!item.id_planes" @click="removerItem(item)">
                    fas fa-trash-alt
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="crearPlanes()">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog()"> Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_editar" width="600">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="id_aseguradora"
                label="Aseguradora"
                :items="items_aseguradoras"
                item-text="descripcion"
                item-value="id_aseguradoras"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="id_categoria"
                label="Categoria"
                :items="items_categoria"
                item-text="descripcion"
                item-value="id_categoria_seguros"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="id_ramo"
                label="Ramo"
                :items="items_ramos"
                item-text="descripcion"
                item-value="id_ramo_seguros"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre_plan"
                label="Nombre del plan"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="comision_inicial"
                label="% Comisión inicial"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="comision_renovacion"
                label="% Comisión renovación"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="actualizarPlan()">Actualizar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogEditar()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    menu_search: false,
    dialog: false,
    dialog_editar: false,
    headers: [
      { text: "Aseguradora", value: "nombre_aseguradora" },
      { text: "Ramo", value: "nombre_ramo" },
      { text: "Categoria", value: "nombre_categoria" },
      { text: "Nombre", value: "nombre" },
      { text: "% Comision inicial", value: "comision_inicial", align: "center" },
      { text: "% Comision renovación", value: "comision_renovacion", align: "center" },
      { text: "Editar", value: "event_edit", align: "center" },
    ],
    items: [],
    headers_planes_estatus: [
      { text: "Nombre", value: "nombre_plan" },
      { text: "% Inicial", value: "comision_inicial", align: "center" },
      { text: "% Renovación", value: "comision_renovacion", align: "center" },
      { text: "Remover", value: "event_delete", align: "center" },
    ],
    items_planes_estatus: [],
    id_planes: 0,
    id_aseguradora: 0,
    id_ramo: 0,
    id_categoria: 0,
    nombre_plan: "",
    comision_inicial: 0,
    comision_renovacion: 0,
    items_aseguradoras: [],
    items_ramos: [],
    items_categoria: [],
    contador: 0,
    //BUSQUEDA
    search_aseguradora: 0,
    search_ramo: 0,
    search_categoria: 0,
  }),
  created() {
    this.listarAseguradoras();
    this.listarRamos();
    this.listarCategoria();
  },
  methods: {
    listarPlanes() {
      if (
        !this.search_aseguradora &&
        !this.search_ramo &&
        !this.search_categoria
      ) {
        return;
      }
      axios
        .get(
          "api/Planes/Listar/" +
            this.search_aseguradora +
            "/" +
            this.search_ramo +
            "/" +
            this.search_categoria
        )
        .then((response) => {
          this.items = response.data;
        });
    },
    listarComplementosPlanes() {
      if (!this.id_aseguradora || !this.id_categoria || !this.id_ramo) {
        return;
      }
      axios
        .get(
          "api/Planes/ListarComplemento/" +
            this.id_aseguradora.id_aseguradoras +
            "/" +
            this.id_ramo.id_ramo_seguros +
            "/" +
            this.id_categoria.id_categoria_seguros
        )
        .then((response) => {
          response.data.forEach((element) => {
            this.items_planes_estatus.push({
              id_planes: element.id_planes,
              id_aseguradora: this.id_aseguradora.id_aseguradoras,
              id_ramo: this.id_ramo.id_ramo_seguros,
              id_categoria: this.id_categoria.id_categoria_seguros,
              nombre_plan: element.nombre,
              comision_inicial: element.comision_inicial,
              comision_renovacion: element.comision_renovacion,
            });
          });
        });
    },
    listarAseguradoras() {
      axios.get("api/Aseguradoras/Listar").then((response) => {
        //console.log("aseg", response.data);
        this.items_aseguradoras = response.data;
      });
    },
    listarRamos() {
      axios.get("api/RamoSeguros/Listar").then((response) => {
        //console.log("ramo", response.data);
        this.items_ramos = response.data;
      });
    },
    listarCategoria() {
      axios.get("api/CategoriaSeguros/Listar").then((response) => {
        //console.log("catego", response.data);
        this.items_categoria = response.data;
      });
    },
    abrirDialog() {
      this.dialog = true;
    },
    addPlan() {
      this.items_planes_estatus.push({
        id: this.contador++,
        id_aseguradora: this.id_aseguradora.id_aseguradoras,
        id_ramo: this.id_ramo.id_ramo_seguros,
        id_categoria: this.id_categoria.id_categoria_seguros,
        nombre_plan: this.nombre_plan,
        comision_inicial: this.comision_inicial,
        comision_renovacion: this.comision_renovacion,
      });
    },
    crearPlanes() {
      this.items_planes_estatus.forEach((element) => {
        if (!element.id_planes) {
          axios.post("api/Planes/Crear", {
            id_aseguradoras: element.id_aseguradora,
            id_ramo_seguros: element.id_ramo,
            id_categoria_seguros: element.id_categoria,
            nombre: element.nombre_plan,
            comision_inicial: element.comision_inicial,
            comision_renovacion: element.comision_renovacion,
          });
        }
      });
      this.listarPlanes();
      this.cerrarDialog();
      this.alerta("info", "Proceso terminado");
    },
    editarItem(item) {
      this.id_planes = item.id_planes;
      this.id_aseguradora = item.id_aseguradoras;
      this.id_categoria = item.id_categoria_seguros;
      this.id_ramo = item.id_ramo_seguros;
      this.nombre_plan = item.nombre;
      this.comision_inicial = item.comision_inicial;
      this.comision_renovacion = item.comision_renovacion;
      this.dialog_editar = true;
    },
    actualizarPlan() {
      axios.put("api/Planes/Actualizar", {
        id_planes: this.id_planes,
        id_aseguradoras: this.id_aseguradora,
        id_ramo_seguros: this.id_ramo,
        id_categoria_seguros: this.id_categoria,
        nombre: this.nombre_plan,
        comision_inicial: this.comision_inicial,
        comision_renovacion: this.comision_renovacion
      }).then(() => {
        this.cerrarDialogEditar();
        this.alerta("info", "Proceso terminado");
        this.listarPlanes();
      })
    },
    removerItem(item) {
      var index = this.items_planes_estatus.findIndex(
        (obj) => obj.id == item.id
      );
      this.items_planes_estatus.splice(index, 1);
    },
    cerrarDialog() {
      this.dialog = false;

      this.items_planes_estatus = [];
      this.id_aseguradora = 0;
      this.id_ramo = 0;
      this.id_categoria = 0;
      this.nombre_plan = "";
      this.comision_inicial = 0;
      this.comision_renovacion = 0;
      this.contador = 0;
    },
    cerrarDialogEditar() {
      this.dialog_editar = false;
      this.id_planes = 0;
      this.id_aseguradora = 0;
      this.id_categoria = 0;
      this.id_ramo = 0;
      this.nombre_plan = "";
      this.comision_inicial = 0;
      this.comision_renovacion = 0;
    },
    alerta(icon, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icon,
        title: mensaje,
      });
    },
  },
};
</script>