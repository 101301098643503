<template>
  <v-row>
    <v-col cols="12">
      <v-tabs v-model="tab" background-color="primary">
        <v-tabs-slider color="#d62828"></v-tabs-slider>
        <v-tab @click="listarPolizasProximasVencer('todos')"
          >Pólizas próximas a vencer</v-tab
        >
        <v-tab @click="listarCuotasProximaPagar('todos')"
          >Cuotas próximas a pagar</v-tab
        >
        <v-tab @click="listarCuotasVencidas('todos')">Cuotas vencidas</v-tab>
        <v-tab @click="listarCuotasPeriodoGracia('todos')"
          >Cuotas en período de gracia</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- POLIZA PROXIMOS A VENCER -->
        <v-tab-item>
          <v-data-table
            v-model="seleccion_polizas_prox_vencer"
            :headers="headers_prox_vencer"
            :items="items_prox_vencer"
            :search="search"
            style="border: 1px grey solid"
            item-key="id_polizas"
            show-select
            :loading="loading"
            loading-text="Cargando... Porfavor espere"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" dark
                      >Descargar</v-btn
                    >
                  </template>
                  <v-list>
                    <template v-for="(item, i) in items_descarga">
                      <v-list-item
                        :key="i"
                        @click="descargarReporteProxVencer(item, 'ppv')"
                        v-if="item.is_show"
                      >
                        <v-list-item-title>{{
                          item.documento
                        }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>fa-solid fa-filter</v-icon></v-btn
                    >
                  </template>
                  <v-card width="300">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_desde"
                            label="Desde"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_hasta"
                            label="Hasta"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          @click="listarPolizasProximasVencer('filtro')"
                        >
                          <v-btn color="primary" block>Filtrar</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.fecha_fin`]="{ item }">
              <span>
                {{ formatoFechaSV(item.fecha_fin) }}
              </span>
            </template>
            <template v-slot:[`item.action_view`]="{ item }">
              <v-icon @click="openTabPoliza(item)"
                >fas fa-external-link-alt</v-icon
              >
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- CUOTAS PROXIMOS A PAGAR -->
        <v-tab-item>
          <v-data-table
            v-model="seleccion_cuotas_prox_pagar"
            :headers="headers_prox_pagar"
            :items="items_prox_pagar"
            :search="search"
            style="border: 1px grey solid"
            item-key="id_cuotas"
            show-select
            :loading="loading"
            loading-text="Cargando... Porfavor espere"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" dark
                      >Descargar</v-btn
                    >
                  </template>
                  <v-list>
                    <template v-for="(item, i) in items_descarga">
                      <v-list-item
                        :key="i"
                        @click="descargarReporteEstatusCuotas(item, 'cpp')"
                        v-if="item.is_show"
                      >
                        <v-list-item-title>{{
                          item.documento
                        }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>fa-solid fa-filter</v-icon></v-btn
                    >
                  </template>
                  <v-card width="300">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_desde"
                            label="Desde"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_hasta"
                            label="Hasta"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          @click="listarCuotasProximaPagar('filtro')"
                        >
                          <v-btn color="primary" block>Filtrar</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
              <span>
                {{ formatoFechaSV(item.fecha) }}
              </span>
            </template>
            <template v-slot:[`item.monto`]="{ item }">
              <span> ${{ item.monto }} </span>
            </template>
            <template v-slot:[`item.action_view`]="{ item }">
              <v-icon @click="openTabPoliza(item)"
                >fas fa-external-link-alt</v-icon
              >
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- CUOTAS VENCIDAS -->
        <v-tab-item>
          <v-data-table
            v-model="seleccion_cuotas_vencidas"
            :headers="headers_cuotas_vencidas"
            :items="items_cuotas_vencidas"
            :search="search"
            style="border: 1px grey solid"
            item-key="id_cuotas"
            show-select
            :loading="loading"
            loading-text="Cargando... Porfavor espere"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" dark
                      >Descargar</v-btn
                    >
                  </template>
                  <v-list>
                    <template v-for="(item, i) in items_descarga">
                      <v-list-item
                        :key="i"
                        @click="descargarReporteEstatusCuotas(item, 'cv')"
                        v-if="item.is_show"
                      >
                        <v-list-item-title>{{
                          item.documento
                        }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>fa-solid fa-filter</v-icon></v-btn
                    >
                  </template>
                  <v-card width="300">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_desde"
                            label="Desde"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_hasta"
                            label="Hasta"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          @click="listarCuotasVencidas('filtro')"
                        >
                          <v-btn color="primary" block>Filtrar</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
              <span>
                {{ formatoFechaSV(item.fecha) }}
              </span>
            </template>
            <template v-slot:[`item.monto`]="{ item }">
              <span> ${{ item.monto }} </span>
            </template>
            <template v-slot:[`item.action_view`]="{ item }">
              <v-icon @click="openTabPoliza(item)"
                >fas fa-external-link-alt</v-icon
              >
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- CUOTAS PERIODO DE GRACIA -->
        <v-tab-item>
          <v-data-table
            v-model="seleccion_cuotas_periodo_gracia"
            class="elevation-3"
            :items="items_cuotas_periodo_gracia"
            :headers="headers_cuotas_periodo_gracia"
            :search="search"
            style="border: 1px grey solid"
            item-key="id_cuotas"
            show-select
            :loading="loading"
            loading-text="Cargando... Porfavor espere"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" dark
                      >Descargar</v-btn
                    >
                  </template>
                  <v-list>
                    <template v-for="(item, i) in items_descarga">
                      <v-list-item
                        :key="i"
                        @click="descargarReporteEstatusCuotas(item, 'cpg')"
                        v-if="item.is_show"
                      >
                        <v-list-item-title>{{
                          item.documento
                        }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      ><v-icon>fa-solid fa-filter</v-icon></v-btn
                    >
                  </template>
                  <v-card width="300">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_desde"
                            label="Desde"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="filtro_fecha_hasta"
                            label="Hasta"
                            outlined
                            dense
                            hide-details
                            type="date"
                            clearable
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          @click="listarCuotasPeriodoGracia('filtro')"
                        >
                          <v-btn color="primary" block>Filtrar</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:[`item.fecha`]="{ item }">
              <span>
                {{ formatoFechaSV(item.fecha) }}
              </span>
            </template>
            <template v-slot:[`item.monto`]="{ item }">
              <span> ${{ item.monto }} </span>
            </template>
            <template v-slot:[`item.action_view`]="{ item }">
              <v-icon @click="openTabPoliza(item)"
                >fas fa-external-link-alt</v-icon
              >
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import axios from "axios";
var XLSX = require("xlsx");

export default {
  data: () => ({
    search: "",
    tab: null,
    headers_prox_vencer: [
      { text: "Nombre", value: "nombre_cliente" },
      { text: "Póliza", value: "num_poliza" },
      { text: "Servicio", value: "nombre_seguro" },
      { text: "Aseguradora", value: "nombre_aseguradora_carpeta" },
      { text: "Fecha vencimiento", value: "fecha_fin" },
      { text: "Ver", value: "action_view", align: "center", sortable: false },
    ],
    items_prox_vencer: [],
    headers_cuotas_vencidas: [
      { text: "Nombre", value: "nombre_cliente" },
      { text: "Póliza", value: "num_poliza" },
      { text: "Servicio", value: "producto" },
      { text: "Aseguradora", value: "nombre_aseguradora_carpeta" },
      { text: "Fecha pago", value: "fecha", width: 120 },
      { text: "Monto cuota", value: "monto" },
      { text: "Ver", value: "action_view", align: "center", sortable: false },
    ],
    items_cuotas_vencidas: [],
    headers_prox_pagar: [
      { text: "Nombre", value: "nombre_cliente" },
      { text: "Póliza", value: "num_poliza" },
      { text: "Servicio", value: "producto" },
      { text: "Aseguradora", value: "nombre_aseguradora_carpeta" },
      { text: "Fecha pago", value: "fecha", width: 120 },
      { text: "Monto cuota", value: "monto" },
      { text: "Ver", value: "action_view", align: "center", sortable: false },
    ],
    items_prox_pagar: [],
    items_cuotas_periodo_gracia: [],
    headers_cuotas_periodo_gracia: [
      { text: "Nombre", value: "nombre_cliente" },
      { text: "Póliza", value: "num_poliza" },
      { text: "Servicio", value: "producto" },
      { text: "Aseguradora", value: "nombre_aseguradora_carpeta" },
      { text: "Fecha pago", value: "fecha", width: 120 },
      { text: "Monto cuota", value: "monto" },
      { text: "Ver", value: "action_view", align: "center", sortable: false },
      {
        text: "Descargar",
        value: "action_download",
        align: "center",
        sortable: false,
      },
    ],
    items_descarga: [
      {
        documento: "PDF",
        tipo_doc: "pdf",
        is_show: true,
      },
      {
        documento: "EXCEL",
        tipo_doc: "xlsx",
        is_show: true,
      },
      {
        documento: "WORD",
        tipo_doc: "doc",
        is_show: true,
      },
    ],
    seleccion_polizas_prox_vencer: [],
    seleccion_cuotas_prox_pagar: [],
    seleccion_cuotas_vencidas: [],
    seleccion_cuotas_periodo_gracia: [],
    loading: false,
    filtro_fecha_desde: "01-01-0001",
    filtro_fecha_hasta: "01-01-0001",
    items_id_poliza: [],
  }),
  created() {
    this.listarPolizasProximasVencer("todos");
  },
  methods: {
    listarPolizasProximasVencer(filtro) {
      this.loading = true;
      this.seleccion_polizas_prox_vencer = [];
      this.seleccion_cuotas_prox_pagar = [];
      this.seleccion_cuotas_vencidas = [];
      this.seleccion_cuotas_periodo_gracia = [];

      var date = new Date();

      var fecha_actual = Date.UTC(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );

      //CALCULO DE DIAS EN TRES MESES
      var hoy = new Date();
      var fecha_hoy = this.formatoFechaSV(hoy);
      var fecha_tres_meses = this.formatoFechaSV(
        hoy.setMonth(date.getMonth() + 3)
      );
      var mdy1 = fecha_hoy.split("/");
      var mdy2 = fecha_tres_meses.split("/");
      var value1 = new Date(mdy1[2], mdy1[1], mdy1[0]);
      var value2 = new Date(mdy2[2], mdy2[1], mdy2[0]);
      var dias_tres_meses = Math.round(
        (value2 - value1) / (1000 * 60 * 60 * 24)
      );

      var day = 1000 * 60 * 60 * 24;

      axios
        .get(
          "api/Polizas/ListarProximasVencer/" +
            this.formatoFecha(date) +
            "/" +
            this.filtro_fecha_desde +
            "/" +
            this.filtro_fecha_hasta +
            "/" +
            filtro
        )
        .then((response) => {
          this.loading = false;
          this.items_prox_vencer = [];
          //console.log(response.data);
          response.data.forEach((element) => {
            var dateResponse = new Date(element.fecha_fin);

            var fecha_fin = Date.UTC(
              dateResponse.getFullYear(),
              dateResponse.getMonth() + 1,
              dateResponse.getDate()
            );

            var diasFaltantes = (fecha_fin - fecha_actual) / day;

            if (diasFaltantes < dias_tres_meses) {
              this.items_prox_vencer.push(element);
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    listarCuotasProximaPagar(filtro) {
      this.loading = true;
      this.seleccion_polizas_prox_vencer = [];
      this.seleccion_cuotas_prox_pagar = [];
      this.seleccion_cuotas_vencidas = [];
      this.seleccion_cuotas_periodo_gracia = [];

      var hoy = new Date();
      var primer_dia = this.formatoFecha(hoy);
      hoy.setMonth(1);
      var ultimo_dia = this.formatoFecha(hoy);

      axios
        .get(
          "api/Polizas/ListarCuotasProximasPagar/" +
            primer_dia +
            "/" +
            ultimo_dia +
            "/" +
            this.filtro_fecha_desde +
            "/" +
            this.filtro_fecha_hasta +
            "/" +
            filtro
        )
        .then((response) => {
          //console.log(response.data);
          this.loading = false;
          this.items_prox_pagar = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    listarCuotasVencidas(filtro) {
      this.loading = true;
      this.seleccion_polizas_prox_vencer = [];
      this.seleccion_cuotas_prox_pagar = [];
      this.seleccion_cuotas_vencidas = [];
      this.seleccion_cuotas_periodo_gracia = [];

      var date = new Date();

      axios
        .get(
          "api/Polizas/ListarCuotasVencidas/" +
            this.formatoFecha(date) +
            "/" +
            this.filtro_fecha_desde +
            "/" +
            this.filtro_fecha_hasta +
            "/" +
            filtro
        )
        .then((response) => {
          //console.log(response.data);
          this.loading = false;
          this.items_cuotas_vencidas = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    listarCuotasPeriodoGracia(filtro) {
      this.loading = true;
      this.seleccion_polizas_prox_vencer = [];
      this.seleccion_cuotas_prox_pagar = [];
      this.seleccion_cuotas_vencidas = [];
      this.seleccion_cuotas_periodo_gracia = [];

      axios
        .get(
          "api/Polizas/ListarCuotasPeriodoGracia/" +
            this.filtro_fecha_desde +
            "/" +
            this.filtro_fecha_hasta +
            "/" +
            filtro
        )
        .then((response) => {
          //console.log(response.data);
          this.loading = false;
          this.items_cuotas_periodo_gracia = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-DD");
    },
    formatoFechaSV(fecha) {
      return moment(fecha).format("DD/MM/YYYY");
    },
    seleccionTipoDoc(item) {
      //console.log(item);
      this.id_polizas = item.id_polizas;
    },
    descargarReporteProxVencer(item, tipo) {
      //console.log(item, tipo);
      if (item.tipo_doc == "pdf") {
        this.descargarPDF();
      }
      if (item.tipo_doc == "xlsx") {
        this.descargarEXCEL(tipo);
      }
      if (item.tipo_doc == "doc") {
        this.descargarWORD();
      }
    },
    descargarReporteEstatusCuotas(item, tipo){
      //console.log(item, tipo);
      if (item.tipo_doc == "pdf") {
        this.generarReporteCuotasPDF();
      }
      if (item.tipo_doc == "xlsx") {
        this.generarReporteCuotasExcel(tipo);
      }
      if (item.tipo_doc == "doc") {
        this.generarReporteCuotasWORD();
      }
    },
    generarReporteCuotasPDF() {
      var items = [];
      var url_end_point = "";
      if (this.seleccion_polizas_prox_vencer.length > 0) {
        items = this.seleccion_polizas_prox_vencer;
      } else if (this.seleccion_cuotas_prox_pagar.length > 0) {
        items = this.seleccion_cuotas_prox_pagar;
        url_end_point = "ReporteCuotasProxPagar";
      } else if (this.seleccion_cuotas_vencidas.length > 0) {
        items = this.seleccion_cuotas_vencidas;
        url_end_point = "ReporteCuotasVencidas";
      } else if (this.seleccion_cuotas_periodo_gracia.length > 0) {
        items = this.seleccion_cuotas_periodo_gracia;
        url_end_point = "ReporteCuotasPeriodoGracia";
      }

      if (
        this.seleccion_polizas_prox_vencer.length <= 0 &&
        this.seleccion_cuotas_prox_pagar.length <= 0 &&
        this.seleccion_cuotas_vencidas.length <= 0 &&
        this.seleccion_cuotas_periodo_gracia.length <= 0
      ) {
        this.alerta("info", "seleccione al menos un registro");
        return;
      }

      //SE AGREGA LA LISTA DE ID_CUOTAS
      this.items_id_poliza = [];
      for (var i = 0; i < items.length; i++) {
        this.items_id_poliza.push(items[i].id_cuotas);
      }

      axios
        .post("api/Polizas/" + url_end_point, {
          id_poliza: this.items_id_poliza,
          tipo_doc: "pdf",
          server: process.env.VUE_APP_MODE_CRYSTAL,
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.split("Æ")[0] == 200) {
            var pdfwindow = window.open("");
            pdfwindow.document.write(
              "<title>"+ url_end_point +"</title>" +
                '<iframe src="data:application/pdf;base64,' +
                response.data.split("Æ")[1] +
                '"frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>' +
                "<style> body {margin:0px;} </style>"
            );
          } else {
            this.alerta("error", response.data.split("Æ")[1]);
          }
        });
    },
    generarReporteCuotasWORD() {
      var items = [];
      var url_end_point = "";
      if (this.seleccion_polizas_prox_vencer.length > 0) {
        items = this.seleccion_polizas_prox_vencer;
      } else if (this.seleccion_cuotas_prox_pagar.length > 0) {
        items = this.seleccion_cuotas_prox_pagar;
        url_end_point = "ReporteCuotasProxPagar";
      } else if (this.seleccion_cuotas_vencidas.length > 0) {
        items = this.seleccion_cuotas_vencidas;
        url_end_point = "ReporteCuotasVencidas";
      } else if (this.seleccion_cuotas_periodo_gracia.length > 0) {
        items = this.seleccion_cuotas_periodo_gracia;
        url_end_point = "ReporteCuotasPeriodoGracia";
      }

      if (
        this.seleccion_polizas_prox_vencer.length <= 0 &&
        this.seleccion_cuotas_prox_pagar.length <= 0 &&
        this.seleccion_cuotas_vencidas.length <= 0 &&
        this.seleccion_cuotas_periodo_gracia.length <= 0
      ) {
        this.alerta("info", "seleccione al menos un registro");
        return;
      }

      //SE AGREGA LA LISTA DE ID_CUOTAS
      this.items_id_poliza = [];
      for (var i = 0; i < items.length; i++) {
        this.items_id_poliza.push(items[i].id_cuotas);
      }

      axios
        .post("api/Polizas/" + url_end_point, {
          id_poliza: this.items_id_poliza,
          tipo_doc: "doc",
          server: process.env.VUE_APP_MODE_CRYSTAL,
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.split("Æ")[0] == 200) {
            var pdfwindow = window.open("");
            pdfwindow.document.write(
              "<title>" + url_end_point + "</title>" +
                '<iframe src="data:application/msword;base64,' +
                response.data.split("Æ")[1] +
                '"frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>' +
                "<style> body {margin:0px;} </style>"
            );
          } else {
            this.alerta("error", response.data.split("Æ")[1]);
          }
        });
    },
    generarReporteCuotasExcel(tipo) {
      var items = [];
      var excel_data = [];

      if (this.seleccion_polizas_prox_vencer.length > 0) {
        items = this.seleccion_polizas_prox_vencer;
      } else if (this.seleccion_cuotas_prox_pagar.length > 0) {
        items = this.seleccion_cuotas_prox_pagar;
      } else if (this.seleccion_cuotas_vencidas.length > 0) {
        items = this.seleccion_cuotas_vencidas;
      } else if (this.seleccion_cuotas_periodo_gracia.length > 0) {
        items = this.seleccion_cuotas_periodo_gracia;
      }

      if (
        this.seleccion_polizas_prox_vencer.length <= 0 &&
        this.seleccion_cuotas_prox_pagar.length <= 0 &&
        this.seleccion_cuotas_vencidas.length <= 0 &&
        this.seleccion_cuotas_periodo_gracia.length <= 0
      ) {
        this.alerta("info", "seleccione al menos un registro");
        return;
      }

      if (tipo == "cpp") {
        items.forEach((element) => {
          excel_data.push({
            "#Póliza": element.num_poliza,
            Cliente: element.nombre_cliente,
            Producto: element.producto,
            Aseguradora: element.nombre_aseguradora_carpeta,
            Categoria: element.categoria,
            Ramo: element.ramo,
            "Tipo seguro": element.tipo_seguro,
            "Numero cuota": element.numero_cuota,
            "Fecha cuota": this.formatoFechaSV(element.fecha),
            "Día de cobro": element.dia_cobro,
            "Monto cuota": element.monto,
          });
        });
      }

      if (tipo == "cv") {
        items.forEach((element) => {
          excel_data.push({
            "#Póliza": element.num_poliza,
            Cliente: element.nombre_cliente,
            Producto: element.producto,
            Aseguradora: element.nombre_aseguradora_carpeta,
            Categoria: element.categoria,
            Ramo: element.ramo,
            "Tipo seguro": element.tipo_seguro,
            "Numero cuota": element.numero_cuota,
            "Fecha cuota": this.formatoFechaSV(element.fecha),
            "Día de cobro": element.dia_cobro,
            "Monto cuota": element.monto,
          });
        });
      }

      if (tipo == "cpg") {
        items.forEach((element) => {
          excel_data.push({
            "#Póliza": element.num_poliza,
            Cliente: element.nombre_cliente,
            Producto: element.producto,
            Aseguradora: element.nombre_aseguradora_carpeta,
            Categoria: element.categoria,
            Ramo: element.ramo,
            "Tipo seguro": element.tipo_seguro,
            "Numero cuota": element.numero_cuota,
            "Fecha cuota": this.formatoFechaSV(element.fecha),
            "Día de cobro": element.dia_cobro,
            "Monto cuota": element.monto,
          });
        });
      }

      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    async descargarPDF() {
      var items = [];
      if (this.seleccion_polizas_prox_vencer.length > 0) {
        items = this.seleccion_polizas_prox_vencer;
      } else if (this.seleccion_cuotas_prox_pagar.length > 0) {
        items = this.seleccion_cuotas_prox_pagar;
      } else if (this.seleccion_cuotas_vencidas.length > 0) {
        items = this.seleccion_cuotas_vencidas;
      } else if (this.seleccion_cuotas_periodo_gracia.length > 0) {
        items = this.seleccion_cuotas_periodo_gracia;
      }

      if (
        this.seleccion_polizas_prox_vencer.length <= 0 &&
        this.seleccion_cuotas_prox_pagar.length <= 0 &&
        this.seleccion_cuotas_vencidas.length <= 0 &&
        this.seleccion_cuotas_periodo_gracia.length <= 0
      ) {
        this.alerta("info", "seleccione al menos un registro");
        return;
      }

      this.items_id_poliza = [];
      for (var i = 0; i < items.length; i++) {
        this.items_id_poliza.push(items[i].id_polizas);
      }

      await axios
        .post("api/Polizas/ReportePolizaProxVencer", {
          id_poliza: this.items_id_poliza,
          tipo_doc: "pdf",
          server: process.env.VUE_APP_MODE_CRYSTAL,
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.split("Æ")[0] == 200) {
            var pdfwindow = window.open("");
            pdfwindow.document.write(
              "<title>Pólizas proximas a vencer</title>" +
                '<iframe src="data:application/pdf;base64,' +
                response.data.split("Æ")[1] +
                '"frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>' +
                "<style> body {margin:0px;} </style>"
            );
          }
        });
    },
    descargarEXCEL(tipo) {
      var items = [];
      var excel_data = [];

      if (this.seleccion_polizas_prox_vencer.length > 0) {
        items = this.seleccion_polizas_prox_vencer;
      } else if (this.seleccion_cuotas_prox_pagar.length > 0) {
        items = this.seleccion_cuotas_prox_pagar;
      } else if (this.seleccion_cuotas_vencidas.length > 0) {
        items = this.seleccion_cuotas_vencidas;
      } else if (this.seleccion_cuotas_periodo_gracia.length > 0) {
        items = this.seleccion_cuotas_periodo_gracia;
      }

      if (
        this.seleccion_polizas_prox_vencer.length <= 0 &&
        this.seleccion_cuotas_prox_pagar.length <= 0 &&
        this.seleccion_cuotas_vencidas.length <= 0 &&
        this.seleccion_cuotas_periodo_gracia.length <= 0
      ) {
        this.alerta("info", "seleccione al menos un registro");
        return;
      }

      if (tipo == "ppv") {
        items.forEach((element) => {
          excel_data.push({
            "#Póliza": element.num_poliza,
            Cliente: element.nombre_cliente,
            Producto: element.nombre_seguro,
            Aseguradora: element.nombre_aseguradora_carpeta,
            Categoria: element.nombre_categoria,
            Ramo: element.nombre_ramo,
            "Tipo seguro": element.nombre_tipo_seguro,
            Cuotas: element.periodo,
            "Día de cobro": element.dia_cobro,
            "Fecha desde": this.formatoFechaSV(element.fecha_inicial),
            "Fecha hasta": this.formatoFechaSV(element.fecha_fin),
            "Prima neta": element.prima_total,
            "Suma asegurada": element.suma_asegurada,
            Fraccionamiento: element.monto_cuota,
            "Gasto emisión": element.gasto_emision,
            IVA: element.iva,
            "Total a pagar": element.total_pagar,
            Plan: element.nombre_plan,
            "Forma de pago": element.metodo_pago,
            Usuario: element.nombre_usuario,
          });
        });
      }

      if (tipo == "cpp") {
        items.forEach((element) => {
          excel_data.push({
            "#Póliza": element.num_poliza,
            Cliente: element.nombre_cliente,
            Producto: element.producto,
            Aseguradora: element.nombre_aseguradora_carpeta,
            Categoria: element.categoria,
            Ramo: element.ramo,
            "Tipo seguro": element.tipo_seguro,
            "Numero cuota": element.numero_cuota,
            "Fecha cuota": this.formatoFechaSV(element.fecha),
            "Día de cobro": element.dia_cobro,
            "Monto cuota": element.monto,
          });
        });
      }

      if (tipo == "cv") {
        items.forEach((element) => {
          excel_data.push({
            "#Póliza": element.num_poliza,
            Cliente: element.nombre_cliente,
            Producto: element.producto,
            Aseguradora: element.nombre_aseguradora_carpeta,
            Categoria: element.categoria,
            Ramo: element.ramo,
            "Tipo seguro": element.tipo_seguro,
            "Numero cuota": element.numero_cuota,
            "Fecha cuota": this.formatoFechaSV(element.fecha),
            "Día de cobro": element.dia_cobro,
            "Monto cuota": element.monto,
          });
        });
      }

      if (tipo == "cpg") {
        items.forEach((element) => {
          excel_data.push({
            "#Póliza": element.num_poliza,
            Cliente: element.nombre_cliente,
            Producto: element.producto,
            Aseguradora: element.nombre_aseguradora_carpeta,
            Categoria: element.categoria,
            Ramo: element.ramo,
            "Tipo seguro": element.tipo_seguro,
            "Numero cuota": element.numero_cuota,
            "Fecha cuota": this.formatoFechaSV(element.fecha),
            "Día de cobro": element.dia_cobro,
            "Monto cuota": element.monto,
          });
        });
      }

      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    async descargarWORD() {
      var items = [];
      if (this.seleccion_polizas_prox_vencer.length > 0) {
        items = this.seleccion_polizas_prox_vencer;
      } else if (this.seleccion_cuotas_prox_pagar.length > 0) {
        items = this.seleccion_cuotas_prox_pagar;
      } else if (this.seleccion_cuotas_vencidas.length > 0) {
        items = this.seleccion_cuotas_vencidas;
      } else if (this.seleccion_cuotas_periodo_gracia.length > 0) {
        items = this.seleccion_cuotas_periodo_gracia;
      }

      if (
        this.seleccion_polizas_prox_vencer.length <= 0 &&
        this.seleccion_cuotas_prox_pagar.length <= 0 &&
        this.seleccion_cuotas_vencidas.length <= 0 &&
        this.seleccion_cuotas_periodo_gracia.length <= 0
      ) {
        this.alerta("info", "seleccione al menos un registro");
        return;
      }

      this.items_id_poliza = [];
      for (var i = 0; i < items.length; i++) {
        this.items_id_poliza.push(items[i].id_polizas);
      }

      await axios
        .post("api/Polizas/ReportePolizaProxVencer", {
          id_poliza: this.items_id_poliza,
          tipo_doc: "doc",
          server: process.env.VUE_APP_MODE_CRYSTAL,
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.split("Æ")[0] == 200) {
            var pdfwindow = window.open("");
            pdfwindow.document.write(
              "<title>Pólizas proximas a vencer</title>" +
                '<iframe src="data:application/msword;base64,' +
                response.data.split("Æ")[1] +
                '"frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>' +
                "<style> body {margin:0px;} </style>"
            );
          }
        });
    },
    openTabPoliza(item) {
      //console.log(item);
      const routeData = this.$router.resolve({
        name: "polizas",
        params: { id: item.num_poliza },
      });
      window.open(routeData.href, "_blank");
    },
    alerta(icono, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: icono,
        title: mensaje,
      });
    },
  },
};
</script>