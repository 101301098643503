//METODOS PARA OBTENER DE LA BASE LOS ACCESSOS, TOKENS DE ONE DRIVE
import axios from "axios"

export default ({
    data: () => ({
        //
    }),
    metodos: {
        async listarClavesOneDrive(id_usuario) {
            const result = await axios.get("api/Seguridades/Listar/" + id_usuario);
            return result.data;
        },
        async getTokenSesion(codigo) {
            const response = await axios.get("api/Seguridades/ListarSesion/" + codigo);
            if(response.data.length > 0) {
                return this.desencriptar(response.data[0].valor);
            } else {
                return null;
            }
        },
        async getTokenCode(codigo_) {
            const result = await axios.post("api/Usuarios/ObtenerToken", {
                codigo: codigo_,
                redirect_url: process.env.VUE_APP_URL_ONE_DRIVE
            }).catch((error) => {
                return error;
            })
            return result;
        },
        async getTokenRefresh(valor) {
            const refresh_token = await axios.post("api/Usuarios/ActualizarToken", {
                refresh_token: valor,
                redirect_url: process.env.VUE_APP_URL_ONE_DRIVE
            });
            return refresh_token;
        },
        async actualizarCredenciales(data, old_credenciales) {
            await axios.put("api/Seguridades/Actualizar", {
                id_seguridad: this.getItemObject("access_token", old_credenciales).id_seguridad,
                id_usuario: this.getItemObject("access_token", old_credenciales).id_usuario,
                codigo: this.getItemObject("access_token", old_credenciales).codigo,
                clave: "access_token",
                valor: this.encriptar(data.access_token)
            }).then(() => {
                console.log("Token actualizado...");
            }).catch((error) => {
                console.log(error);
            })

            await axios.put("api/Seguridades/Actualizar", {
                id_seguridad: this.getItemObject("expires_in", old_credenciales).id_seguridad,
                id_usuario: this.getItemObject("expires_in", old_credenciales).id_usuario,
                codigo: this.getItemObject("expires_in", old_credenciales).codigo,
                clave: "expires_in",
                valor: new Date().getTime() + 1 * 60 * 60 * 1000
            }).then(() => {
                console.log("Expire in actualizado...");
            }).catch((error) => {
                console.log(error);
            });
            return;
        },
        //ENCRIPTACION
        encriptar(token) {
            var CryptoJS = require("crypto-js");
            var encrypted = CryptoJS.AES.encrypt(token, "secret key devco").toString();
            return encrypted;
        },
        async desencriptar(token_encriptado) {
            var CryptoJS = require("crypto-js");
            var decrypted = CryptoJS.AES.decrypt(
                token_encriptado,
                "secret key devco"
            );
            return decrypted.toString(CryptoJS.enc.Utf8);
        },
        getItemObject(item, items = []) {
            for (var i = 0; i < items.length; i++) {
                if (items[i]["clave"] == item) {
                    return items[i];
                }
            }
        },
    }
})