import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router'
import apiLocal from "../AccesosToken/Api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUsuario(state, usuario) {
      state.usuario = usuario;
    },
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token);
      commit("setUsuario", decode(token));
    },
    async autoLogin({ commit }) {
      let token = localStorage.getItem("sesion");
      if (token) {
        var result = await apiLocal.metodos.getTokenSesion(localStorage.getItem("sesion"));
        if (result != null) {
          commit("setToken", result);
          commit("setUsuario", decode(result));
        } else {
          commit("setToken", null);
          commit("setUsuario", null);
          localStorage.removeItem("sesion");
          router.push({ name: "inicioSesion" }).catch(() => { })
        }
      } else {
        router.push({ name: "inicioSesion" }).catch(() => { })
      }
    },
    salir({ commit }) {
      commit("setToken", null);
      commit("setUsuario", null);
      localStorage.removeItem("sesion");
      router.push({ name: "inicioSesion" }).catch(() => { })
    },
  },
})
