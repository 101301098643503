<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="cliente"
                label="Asegurado"
                :items="items_clientes"
                item-text="nombre"
                item-value="id_cliente"
                outlined
                dense
                hide-details
                @input="listarDetalles()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-spacer></v-spacer>
              <v-menu v-model="menu" offset-y transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="menu == !menu"
                  >
                    Descargar
                    <v-icon right v-if="!menu">fas fa-chevron-down</v-icon>
                    <v-icon right v-else>fas fa-chevron-up</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in items_docs"
                    :key="index"
                    link
                    @click="descargarReporte(item)"
                  >
                    <v-list-item-title>{{ item.documento }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-data-table
          v-model="detalle_seleccionado"
          :headers="headers_resultado"
          :items="items_resultado"
          :search="search"
          item-key="id_polizas"
          show-select
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Resultado</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fas fa-search"
                label="Busqueda"
                single-line
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.fecha_inicial`]="{ item }">
            <span>{{ formatoFecha(item.fecha_inicial) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <embed class="emb" v-if="preview_base64" :src="base64_doc" width="100%" height="100%" />
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
var XLSX = require("xlsx");
import apiOneDrive from "../../ApiOneDrive/API";

export default {
  data: () => ({
    preview_base64: false,
    base64_doc: "",
    search: "",
    cliente: "",
    items_clientes: [],
    aseguradora: "",
    items_aseguradoras: [],
    headers_resultado: [
      { text: "N° Póliza", value: "num_poliza" },
      { text: "Cliente", value: "nombre_cliente" },
      { text: "Aseguradora", value: "nombre_aseguradora_carpeta" },
      { text: "Tipo seguro", value: "nombre_seguro" },
      { text: "Celular", value: "telefono_cliente" },
      { text: "Email", value: "correo_cliente" },
    ],
    items_resultado: [],
    detalle_seleccionado: [],
    items_docs: [
      { documento: "PDF (One Drive)", tipo_doc: "pdf" },
      { documento: "WORD (One Drive)", tipo_doc: "doc" },
      { documento: "EXCEL (xls, One Drive)", tipo_doc: "xls" },
      { documento: "EXCEL (xlsx)", tipo_doc: "xlsx" },
    ],
    menu: false,
    pathPdf: "",
    items_pdf: [],
    test_file: "",
  }),
  created() {
    this.listarClientes();
  },
  methods: {
    listarClientes() {
      axios.get("api/Clientes/Listar").then((response) => {
        this.items_clientes = response.data;
      });
    },
    listarDetalles() {
      this.detalle_seleccionado = [];
      axios.get("api/Polizas/Listar/" + this.cliente).then((response) => {
        //console.log(response.data);
        this.items_resultado = response.data;
      });
    },
    async descargarReporte(doc) {
      if (this.detalle_seleccionado.length <= 0) {
        this.alerta("info", "Seleccionar al menos un registro");
        return;
      }
      var cont = 0;
      if (doc.tipo_doc == "pdf") {
        for (var i = 0; i < this.detalle_seleccionado.length; i++) {
          await axios
            .get(
              "api/Polizas/ReporteEstadoCuenta/" +
                this.detalle_seleccionado[i].id_polizas +
                "/" +
                doc.tipo_doc +
                "/" +
                process.env.VUE_APP_MODE_CRYSTAL
            )
            .then(async (response) => {
              //console.log(response.data);
              this.pathPdf = response.data.split("Æ");
              if (this.pathPdf[0] == 200) {
                this.alerta("success", "Reporte Generado");
                this.base64_doc =
                  "data:application/pdf;base64," + this.pathPdf[1];
                //this.preview_base64 = true;
                var result = await apiOneDrive.metodos.CrearArchivoEstadoCuenta(
                  this.$store.state.usuario.id_usuario,
                  this.detalle_seleccionado[i].nombre_cliente_carpeta,
                  this.detalle_seleccionado[i].nombre_aseguradora_carpeta,
                  this.detalle_seleccionado[i].fecha_inicial,
                  this.detalle_seleccionado[i].nombre_categoria,
                  this.detalle_seleccionado[i].nombre_ramo,
                  this.detalle_seleccionado[i].num_poliza,
                  ".pdf",
                  this.base64ToBlob(this.pathPdf[1], "application/pdf")
                );
                await apiOneDrive.metodos.VerArchivo(
                  this.$store.state.usuario.id_usuario,
                  result.data.id
                );
              } else {
                this.alerta("info", this.pathPdf[1]);
              }
            })
            .catch((error) => {
              this.alerta("error", error);
            });
        }
      }
      if (doc.tipo_doc == "doc") {
        for (var j = 0; j < this.detalle_seleccionado.length; j++) {
          await axios
            .get(
              "api/Polizas/ReporteEstadoCuenta/" +
                this.detalle_seleccionado[j].id_polizas +
                "/" +
                doc.tipo_doc +
                "/" +
                process.env.VUE_APP_MODE_CRYSTAL
            )
            .then(async (response) => {
              //console.log(response.data);
              this.pathPdf = response.data.split("Æ");
              if (this.pathPdf[0] == 200) {
                this.alerta("success", "Reporte Generado");

                var result = await apiOneDrive.metodos.CrearArchivoEstadoCuenta(
                  this.$store.state.usuario.id_usuario,
                  this.detalle_seleccionado[j].nombre_cliente_carpeta,
                  this.detalle_seleccionado[j].nombre_aseguradora_carpeta,
                  this.detalle_seleccionado[j].fecha_inicial,
                  this.detalle_seleccionado[j].nombre_categoria,
                  this.detalle_seleccionado[j].nombre_ramo,
                  this.detalle_seleccionado[j].num_poliza,
                  ".doc",
                  this.base64ToBlob(this.pathPdf[1], "application/msword")
                );
                await apiOneDrive.metodos.VerArchivo(
                  this.$store.state.usuario.id_usuario,
                  result.data.id
                );
              } else {
                this.alerta("info", this.pathPdf[1]);
              }
            })
            .catch((error) => {
              this.alerta("error", error);
            });
        }
      }
      if (doc.tipo_doc == "xls") {
        var items = [];
        for (var i = 0; i < this.detalle_seleccionado.length; i++) {
          cont = cont + 1;
          await axios
            .get(
              "api/Polizas/ReporteEstadoCuenta/" +
                this.detalle_seleccionado[i].id_polizas +
                "/" +
                doc.tipo_doc +
                "/" +
                process.env.VUE_APP_MODE_CRYSTAL
            )
            .then(async (response) => {
              //console.log(response.data);
              this.pathPdf = response.data.split("Æ");
              if (this.pathPdf[0] == 200) {
                this.alerta("success", "Reporte Generado");

                var result = await apiOneDrive.metodos.CrearArchivoEstadoCuenta(
                  this.$store.state.usuario.id_usuario,
                  this.detalle_seleccionado[i].nombre_cliente_carpeta,
                  this.detalle_seleccionado[i].nombre_aseguradora_carpeta,
                  this.detalle_seleccionado[i].fecha_inicial,
                  this.detalle_seleccionado[i].nombre_categoria,
                  this.detalle_seleccionado[i].nombre_ramo,
                  this.detalle_seleccionado[i].num_poliza,
                  ".xls",
                  this.base64ToBlob(this.pathPdf[1], "application/vnd.ms-excel")
                );
                await apiOneDrive.metodos.VerArchivo(
                  this.$store.state.usuario.id_usuario,
                  result.data.id
                );
              } else {
                this.alerta("info", this.pathPdf[1]);
              }
            });
        }
      }
      if (doc.tipo_doc == "xlsx") {
        var items = [];
        for (var i = 0; i < this.detalle_seleccionado.length; i++) {
          cont = cont + 1;
          await axios
            .get(
              "api/Polizas/ExcelEstadoCuenta/" +
                this.detalle_seleccionado[i].id_polizas
            )
            .then(async (response) => {
              //console.log(response.data);
              for (var i = 0; i < response.data.length; i++) {
                items.push({
                  "# Poliza": this.items_resultado[i].num_poliza,
                  Factura: response.data[i].factura,
                  Documento: response.data[i].nombre_tipo_documento,
                  "# Cuota": response.data[i].n_cuota,
                  Fecha: this.formatoFechaSV(response.data[i].fecha),
                  Monto: "$" + response.data[i].monto,
                });
              }
              items.push({});

              if (cont == this.detalle_seleccionado.length) {
                this.generarExcel(items);
              }
            });
        }
      }
    },
    generarExcel(excel_data) {
      let data = XLSX.utils.json_to_sheet(excel_data);
      const workbook = XLSX.utils.book_new();
      const filename = "Estado de cuenta";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },
    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-DD");
    },
    formatoFechaSV(fecha) {
      return moment(fecha).format("DD-MM-YYYY");
    },
    alerta(icono, mensaje) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 0,
      });

      Toast.fire({
        icon: icono,
        title: mensaje,
      });
    },
  },
};
</script>