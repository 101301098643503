<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-dialog v-model="dialog" width="auto" persistent>
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <div class="text-center headline font-weight-black">
             {{ texto_mensaje }}
            </div>
            <v-img
              v-if="bool_mensaje"
              class="mx-auto"
              :src="require('@/assets/check.png')"
              width="300"
            ></v-img>
            <div class="text-center subtitle-1 font-weight-medium">
              CERRAR ESTA PESTAÑA
              {{ sesionOneDrive }}
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import apiLocal from "../AccesosToken/Api";
import axios from "axios";

export default {
  data: () => ({
    dialog: true,
    codigo: null,
    id_usuario: null,
    texto_mensaje: "",
    bool_mensaje: false
  }),
  computed: {
    sesionOneDrive() {
      if (this.$store.state.usuario) {
        this.id_usuario = this.$store.state.usuario.id_usuario;
        this.obtenerCodigoURL();
      }
    },
  },
  methods: {
    obtenerCodigoURL() {
      let url = window.location.href;
      let listUrl = url.split("=");
      let codigo_emp = "";

      codigo_emp = listUrl[1].split("&")[0];

      this.obtenerToken(codigo_emp);
    },
    async obtenerToken(codigo) {
      const result = await apiLocal.metodos.getTokenCode(codigo);
      if(result.data.error == "invalid_request") {
        this.texto_mensaje = "NO SE PUDO AUTENTICAR CUENTA";
        this.bool_mensaje = false;
      } else {
        this.texto_mensaje = "AUTENTICACION DE LA CUENTA CON EXITO";
        this.bool_mensaje = true;
        this.guardarAccessTokenOneDrive(result.data);
        this.guardarRefreshTokenOneDrive(result.data);
        this.guardarExpiracionTokenOneDrive();
      }
    },
    async guardarAccessTokenOneDrive(valor) {
      await axios
        .post("api/Seguridades/Crear", {
          id_usuario: this.$store.state.usuario.id_usuario,
          codigo: this.generarCode("access_token"),
          clave: "access_token",
          valor: apiLocal.metodos.encriptar(valor.access_token),
        })
        .then(() => {
          //GUARDA EN BD EL TOKEN DE ONE DRIVE
        })
        .catch((error) => {
          //
        });
    },
    async guardarRefreshTokenOneDrive(valor) {
      await axios
        .post("api/Seguridades/Crear", {
          id_usuario: this.$store.state.usuario.id_usuario,
          codigo: this.generarCode("refresh_token"),
          clave: "refresh_token",
          valor: valor.refresh_token,
        })
        .then(() => {
          //GUARDA EN BD UN REFRESH TOKEN DE ONE DRIVE
        })
        .catch((error) => {
          //
        });
    },
    async guardarExpiracionTokenOneDrive() {
      await axios
        .post("api/Seguridades/Crear", {
          id_usuario: this.$store.state.usuario.id_usuario,
          codigo: this.generarCode("expires_in"),
          clave: "expires_in",
          valor: new Date().getTime() + 1 * 60 * 60 * 1000,
        })
        .then(() => {
          //GUARDA EN BD UNA HORA DE EXPIRACION DEL TOKEN DE ONE DRIVE
        })
        .catch((error) => {
          //
        });
    },
    generarCode(clave) {
      this.codigo = window.crypto
        .getRandomValues(new BigUint64Array(4))
        .reduce(
          (prev, curr, index) =>
            (!index ? prev : prev.toString(36)) +
            (index % 2 ? curr.toString(36).toUpperCase() : curr.toString(36))
        )
        .split("")
        .sort(() => 128 - window.crypto.getRandomValues(new Uint8Array(1))[0])
        .join("");

      if (clave == "sesion") localStorage.setItem(clave, this.codigo);

      return this.codigo;
    },
  },
};
</script>